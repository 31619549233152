var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions"},[_c('div',{staticClass:"institutions-line"},[_c('div',{staticClass:"institutions-line-left"},[_vm._m(0),_c('div',{staticClass:"institutions-line-content"},[_c('span',{staticClass:"institutions-count"},[_vm._v(_vm._s(_vm.org.total + _vm.total))]),_c('span',{staticClass:"institutions-desc"},[_vm._v("机构数")])])]),_c('div',{staticClass:"institutions-line-left"},[_vm._m(1),_c('div',{staticClass:"institutions-line-content"},[_c('span',{staticClass:"institutions-count"},[_vm._v(_vm._s(_vm.org.bedTotal + _vm.bedTotal))]),_c('span',{staticClass:"institutions-desc"},[_vm._v("床位总数")])])])]),_c('div',{staticClass:"institutions-line"},[_c('div',{staticClass:"institutions-line-left"},[_vm._m(2),_c('div',{staticClass:"institutions-line-content"},[_c('span',{staticClass:"institutions-count"},[_vm._v(_vm._s(((_vm.org.bedTotal + _vm.bedTotal)*0.51).toFixed()))]),_c('span',{staticClass:"institutions-desc"},[_vm._v("护理床位")])])]),_c('div',{staticClass:"institutions-line-left"},[_vm._m(3),_c('div',{staticClass:"institutions-line-content"},[_c('span',{staticClass:"institutions-count"},[_vm._v(_vm._s(((_vm.org.bedTotal + _vm.bedTotal)*0.49).toFixed()))]),_c('span',{staticClass:"institutions-desc"},[_vm._v("普通床位")])])])]),_c('div',{staticClass:"institutions-line"},[_c('div',{staticClass:"institutions-line-left"},[_vm._m(4),_c('div',{staticClass:"institutions-line-content"},[_c('span',{staticClass:"institutions-count"},[_vm._v(_vm._s(_vm.org.inBedTotal + _vm.inBedTotal))]),_c('span',{staticClass:"institutions-desc"},[_vm._v("总入住床位")])])]),_c('div',{staticClass:"institutions-line-left"},[_vm._m(5),_c('div',{staticClass:"institutions-line-content"},[_c('span',{staticClass:"institutions-count"},[_vm._v(_vm._s(_vm.org.monInBedTotal))]),_c('span',{staticClass:"institutions-desc"},[_vm._v("入住床位")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions-line-img"},[_c('img',{attrs:{"src":require("../../../image/left/institutionNum.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions-line-img"},[_c('img',{attrs:{"src":require("../../../image/left/bedCount.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions-line-img"},[_c('img',{attrs:{"src":require("../../../image/left/nurseBed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions-line-img"},[_c('img',{attrs:{"src":require("../../../image/left/usualBed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions-line-img"},[_c('img',{attrs:{"src":require("../../../image/left/addBed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"institutions-line-img"},[_c('img',{attrs:{"src":require("../../../image/left/monthAdd.png"),"alt":""}})])
}]

export { render, staticRenderFns }