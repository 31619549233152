<template>
  <!-- 老年用品市场年度销售 -->
  <div class="old">
    <div class="old-p">
      <div class="old-title">
        老年用品市场年度销售
      </div>
      <div class="oldChart">
        <div id="old" style="width: 100%; height: 100%"></div>
      </div>
    </div>
  </div>
</template>
<script>
//引入echarts
let echarts = require('echarts')

export default {
  mounted() {
    // 综合体养老服务中心
    this.init()
  },
  props: {
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  methods: {
    init() {
      const provinceName = sessionStorage.getItem('provinceName')
      const cityName = sessionStorage.getItem('cityName')
      let data
      if (this.levelAndCode) {
        if (this.levelAndCode.level == 'PROVINCE') {
          if (provinceName == '四川省') {
            data = [66.76, 61.70, 7.13, 2.90, 2.35]
            this.oldChart('万元', data)
          } else if (provinceName == '云南省') {
            data = [14.22, 13.14, 2.71, 0.61, 0.30]
            this.oldChart('万元', data)
          } else {
            data = [0, 0, 0, 0, 0]
            this.oldChart('万元', data)
          }
        } else if (this.levelAndCode.level == 'CITY') {
          if (cityName == '成都市') {
            data = [25.97, 22.00, 3.31, 1.12, 2.13]
            this.oldChart('万元', data)
          } else if (cityName == '达州市') {
            data =  [8.27, 16.48, 1.41, 0.35, 0.04]
            this.oldChart('万元', data)
          } else if (cityName == '广安市') {
            data = [5.53, 1.18, 0.27, 0.24, 0.02]
            this.oldChart('万元', data)
          } else if (cityName == '乐山市') {
            data = [5.31, 9.08, 1.26, 0.23, 0.02]
            this.oldChart('万元', data)
          } else if (cityName == '眉山市') {
            data = [5.01, 6.37, 1.25, 0.21, 0.02]
            this.oldChart('万元', data)
          } else if (cityName == '南充市') {
            data = [9.92, 1.68, 0.50, 0.43, 0.05]
            this.oldChart('万元', data)
          } else if (cityName == '雅安市') {
            data = [2.11, 1.95, 0.10, 0.09, 0.01]
            this.oldChart('万元', data)
          } else if (cityName == '自贡市') {
            data = [4.62, 4.27, 0.23, 0.20, 0.02]
            this.oldChart('万元', data)
          } else if (cityName == '红河哈尼族彝族自治州') {
            data = [4.57, 4.23, 0.23, 0.19, 0.22]
            this.oldChart('万元', data)
          } else if (cityName == '昆明市') {
            data = [8.39, 7.76, 1.42, 0.36, 0.14]
            this.oldChart('万元', data)
          } else if (cityName == '丽江市') {
            data = [1.24, 1.15, 0.62, 0.05, 0.04]
            this.oldChart('万元', data)
          } else {
            data = [0, 0, 0, 0, 0]
            this.oldChart('万元', data)
          }
        }
      } else {
        data = [80.98, 74.84, 9.84, 8.98,2.67]
        this.oldChart('万元', data)
      }
    },
    // 综合体养老服务中心
    oldChart(unit, data) {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('old'))
        // 绘制图表
        myChart.setOption({
          title: {
            bottom: '0%',
            left: 'center',
            textStyle: { fontSize: 16 },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
            formatter: function(params) {
              let res =
                '<div><span>' +
                params[0].name +
                '：</span>' +
                params[0].data +
                `${unit}</div>`
              return res
            },
          },
          grid: {
            top: '19%',
            left: '2%',
            right: '0%',
            bottom: '1%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              axisLabel: {
                textStyle: { fontSize: 11, color: '#6ECAFF' },
                interval: 0,
                formatter: function(value) {
                  var result = ''
                  for (let i = 0; i < value.length; i++) {
                    result += value[i]
                    if ((i + 1) % 6 == 0) result += '\n'
                  }
                  return result
                },
              },
              axisLine: {
                lineStyle: { color: '#6ECAFF' },
              },
              data: ['生活用品', '养生食品', '辅具', '健康检测', '医疗'],
            },
          ],
          yAxis: {
            type: 'value',
            name: `金额(${unit})`,
            nameTextStyle: {
              fontSize: 12,
              color: '#6ECAFF',
              padding: [0, 0, 3, 2],
            },
            axisLabel: {
              textStyle: { fontSize: 12, color: '#6ECAFF' },
            },
            axisLine: {
              lineStyle: { color: '#6ECAFF' },
            },
            splitLine: { show: false },
            axisTick: {
              show: false,
            },
          },
          series: [
            {
              type: 'bar',
              barWidth: '60%',
              itemStyle: {
                normal: {
                  color: function(params) {
                    let colorList = [
                      '#FF7B3A',
                      '#43C74C',
                      '#FFCC00',
                      '#3A8DFF',
                      '#3AEFFF',
                    ]
                    return colorList[params.dataIndex]
                  },
                  label: {
                    formatter: '{c}' + `${unit}`,
                    show: true,
                    position: 'top',
                    textStyle: {
                      fontWeight: 'bolder',
                      fontSize: '12',
                      color: '#6ECAFF',
                    },
                  },
                  opacity: 1,
                },
              },
              data: data,
            },
          ],
        })
      })

      window.addEventListener('resize', function() {
        myChart.resize()
      })
    },
  },
}
</script>
<style scoped>
.old {
  position: relative;
  background: url(../../../../image/top/borderback.png) 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
}
.old-p {
  color: #ffffff;
  padding: 0.5rem;
}
.old-title {
  line-height: 1.3rem;
  font-size: 0.7rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #7fb5f6;
}
.oldChart {
  height: 8.75rem;
  width: 100%;
}
</style>
