// 过滤器  超过十万的取万+  ，没超过十万的取正常值但是后面跟小数点后两位
export const moneyDetail = (data) => {
  let k = 100000
  if (data === 0) {
    return data
  }
  if (data < k) {
    var value = Math.round(parseFloat(data) * 100) / 100
    var s = value.toString().split('.')
    if (s.length == 1) {
      value = value.toString() + '.00'
      console.log(value)
      return value
    }
    if (s.length > 1) {
      if (s[1].length < 2) {
        value = value.toString() + '0'
      }
      console.log(value)
      return value
    }
  } else {
    if (data > 100000000) {
      return Math.round((data / 100000000) * 100) / 100 + '亿+'
    } else {
      if (Math.round((data / 10000) * 100) / 100 > 10000) {
        return (Math.round((data / 10000) * 100) / 100).toFixed(0) + '万+'
      }
      return Math.round((data / 10000) * 100) / 100 + '万+'
    }
  }
}

//全部转换为万为单位的
export const tenThousand = (data) => {
  if (data === 0) {
    return data
  }
  if (data > 100000000) {
    return Math.round((data / 100000000) * 100) / 100 + '亿+'
  } else {
    if (Math.round((data / 10000) * 100) / 100 > 10000) {
      return (Math.round((data / 10000) * 100) / 100).toFixed(0) + '万+'
    }
    return Math.round((data / 10000) * 100) / 100 + '万+'
  }
}
export const moneyDetails = (data) => {
  let k = 100000
  if (data === 0) {
    return data
  }
  if (data < k) {
    var value = Math.round(parseFloat(data) * 100) / 100
    var s = value.toString().split('.')
    if (s.length == 1) {
      value = value.toString() + '.00'
      console.log(value)
      return value
    }
    if (s.length > 1) {
      if (s[1].length < 2) {
        value = value.toString() + '0'
      }
      console.log(value)
      return value
    }
  } else {
    return (Math.round((data / 10000) * 100) / 100).toFixed(0) + '万+'
  }
}

export const moneyFormat = (data) => {
  if (!data) return '0.00'
  // 将数据分割，保留两位小数
  data = data.toFixed(2)
  // 获取整数部分
  const intPart = Math.trunc(data)
  // 整数部分处理，增加,
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = '.00'
  // 将数据分割为小数部分和整数部分
  const newArr = data.toString().split('.')
  if (newArr.length === 2) {
    // 有小数部分
    floatPart = newArr[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}

// 同金额处理 但是不保留两位小数
export const peopleFormat = (data) => {
  if (!data) return '0'
  // 将数据分割，保留两位小数
  data = data.toFixed(2)
  // 获取整数部分
  const intPart = Math.trunc(data)
  // 整数部分处理，增加,
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')

  return intPartFormat
}

// 保留两位小数处理
export const fixTwice = (value) => {
  const itemVal = Number(value).toFixed(3)
  if (itemVal === 'NaN') {
    return '0.00'
  }
  const realVal = itemVal.substring(0, itemVal.length - 1)
  return realVal
}
// 过滤器  超过十万的取万+  ，没超过十万的取正常值但
export const account = (data) => {
  let k = 10000
  if (data === 0) {
    return data
  }
  if (data < k) {
    var value = Math.round(parseFloat(data) * 100) / 100
    var s = value.toString().split('.')
    if (s.length == 1) {
      value = value.toString()
      console.log(value)
      return value
    }
    if (s.length > 1) {
      if (s[1].length < 2) {
        value = value.toString()
      }
      console.log(value)
      return value
    }
  } else {
    if (Math.round((data / 10000) * 100) / 100 > 10000) {
      return (Math.round((data / 10000) * 100) / 100).toFixed(0) + '万+'
    }
    return Math.round((data / 10000) * 100) / 100 + '万+'
  }
}
