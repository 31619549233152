import request from '@/utils/request'
import qs from 'qs'
// 用户登录
export function login(data) {
  data.grant_type = 'password'
  data.client_id = 'web'
  data.client_secret = 'web'
  data.scope = 'server'
  return request({
    url: '/ums/oauth/token',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data),
    type: 'ums',
  })
}

// 获取用户信息
export function getInfo() {
  return request({
    url: '/ums/v1/user/info?businessType=SCREEN',
    method: 'get',
    type: 'ums',
    needTenantNo: true,
  })
}
export function logout() {
  return request({
    url: '/ums/token/logout',
    method: 'DELETE',
    type: 'ums',
    needTenantNo: true,
  })
}

//根据code获取token
export const getTokenFromCode = (data) =>
  request({
    url: '/ums/oauth/exchange/token?code=' + data,
    method: 'post',
    type: 'ums',
  })
