// vue路由
import router from './router'
// 引入vuex
// 引入element-ui中的message消息提示
import { Message } from 'element-ui'
import store from './store'
// 引入NProgress进度条插件
import NProgress from 'nprogress'
// NProgress进度条样式
import 'nprogress/nprogress.css'
// 从cookie中获取令牌
import { getToken, setToken, removeToken, setTenantNo } from '@/utils/tools.js'
import { getTokenFromCode } from '@/api/user'
// 获取页面标题
// import getPageTitle from "@/utils/get-page-title";
// \ n程序配置
NProgress.configure({ showSpinner: false })
// 白名单中的重定向
const whiteList = ['/login']
//vue router全局前置守卫
router.beforeEach(async (to, from, next) => {
  // 开始进度条
  NProgress.start()
  //确定用户是否已登录
  const hasToken = getToken()
  //判断是否有token
  if (hasToken) {
    // 如果已登录，请重定向到主页
    if (to.path === '/login') {
      // 进入到首页
      next({ path: '/' })
      // 结束进度条
      NProgress.done()
    } else {
      try {
        // 进入管道的下一个钩子
        const data = await store.dispatch('user/getInfo')
        console.log(data)
        const list = data.data.resources.map((item) => {
          return item.code
        })
        //判断是否拥有大屏权限
        if (list.includes('region_screen')) {
          next()
          NProgress.done()
        } else {
          Message.error('该账号未有数字化大屏权限')
          removeToken()
          // 从新定向到登录页
          next(`/login`)
          NProgress.done()
        }
      } catch (error) {
        // 删除令牌并转到登录页面重新登录
        removeToken()
        // 消息提示错误
        // Message.error(error || '异常情况')
        // 从新定向到登录页
        next(`/login?redirect=${to.path}`)
        // 结束
        NProgress.done()
      }
    }
  } else {
    /* 没有token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      // 没有访问权限的其他页面将重定向到登录页面。
      // next(`/login?redirect=${to.path}`);
      let query = window.location.search.substring(1)
      let vars = query.split('&')
      let code
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == 'code') {
          code = pair[1]
        }
      }
      if (code) {
        getTokenFromCode(code)
          .then((res) => {
            console.log(res.data)
            if (res.data.code == 0) {
              setToken(res.data.data.token_type + res.data.data.access_token)
              setTenantNo(res.data.data.account.tenantNo)
              next()
              NProgress.done()
            } else {
              next(`/login`)
              NProgress.done()
            }
          })
          .catch(() => {
            next(`/login`)
            NProgress.done()
          })
      } else {
        // 结束进度条
        next(`/login`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // 结束进度条
  NProgress.done()
})
