<template>
  <div class="column">
    <br />
    <!-- 颐伦养老机构实时数据 -->
    <leftTop :org="org" :levelAndCode="levelAndCode"></leftTop>
    <!-- 综合体服务实时人数 -->
    <leftMiddle
      :community="community"
      :levelAndCode="levelAndCode"
    ></leftMiddle>
    <!-- 近七日新增用户数 -->
    <leftBottom :levelAndCode="levelAndCode"></leftBottom>
  </div>
</template>

<script>
import { getInstitutionStoreType } from '@/api/allData'
import leftTop from './components/left_top.vue'
import leftMiddle from './components/left_middle.vue'
import leftBottom from './components/left_bottom.vue'
export default {
  // 定义数据
  data() {
    return {
      // 机构的数据
      org: {
        /**
         * 床位总数
         */
        bedTotal: 0,
        /**
         * 普通床位
         */
        commonBedTotal: 0,

        /**
         * 总入住床位总数
         */
        inBedTotal: 0,
        /**
         * 今月新增：入住人数
         */
        monInBedTotal: 0,
        /**
         * 护理床位
         */
        nurseBedTotal: 0,
        /**
         * 机构数|综合体数
         */
        total: 0,
      },
      //   社区
      community: {
        /**
         * 今日新增：入住人数
         */
        curInBedTotal: 0,
        /**
         * 今日新增：服务人次
         */
        curServiceTotal: 0,
        /**
         * 今日新增：会员人数
         */
        curVipTotal: 0,
        /**
         * 总消费金额
         */
        orderAmount: 0,
        /**
         * 总服务人次
         */
        serviceTotal: 0,
        /**
         * 机构数|综合体数
         */
        total: 0,
      },
    }
  },
  components: {
    leftTop,
    leftMiddle,
    leftBottom,
  },
  props: {
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  // dom加载完成后
  mounted() {
    this.init()
  },

  // 定义函数
  methods: {
    //居家养老服务概况
    async init() {
      // 机构养老统计
      const res = await getInstitutionStoreType(
        this.levelAndCode ? this.levelAndCode : null
      )
      if (res.data.code === 0) {
        this.org = res.data.data.org
        this.community = res.data.data.community
      }
    },
  },
}
</script>

<style scoped></style>
