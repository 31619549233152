import axios from 'axios'
// import { MessageBox, Message } from "element-ui";
import { MessageBox, Message } from 'element-ui'
import { getToken, getTenantNo } from '@/utils/tools.js'
// 创建axios实例
// var base
const base = process.env.VUE_APP_BASE_URL
let host = '/hp/platform/data'

const service = axios.create({
  baseURL: base + '/hp/platform/data',
  timeout: 10000000, // 请求超时
})
//退出提示次数
let signOutMesssage = 0
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const { type, needTenantNo } = config
    if (type === 'ums') {
      config.baseURL = base
    } else {
      config.baseURL = base + host
    }
    let token = getToken()
    if (token && !needTenantNo) {
      config.headers['Authorization'] = token
    } else if (token && needTenantNo) {
      config.headers['Authorization'] = getToken()
      config.headers['YL-TENANT'] = getTenantNo()
    }
    return config
  },
  (error) => {
    // 处理请求错误
    console.log(error) // 用于调试
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    let res = response
    console.log('当前请求结果', res)
    if (res.data.code !== 0 && res.data.code !== 60001) {
      Message({
        message: res.data.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(new Error(res.data.message || 'Error'))
    }
    return res
  },
  (error) => {
    try {
      signOutMesssage++
      if (signOutMesssage <= 1) {
        if (
          error.response.data.code === 87650002 ||
          error.response.data.code === 87650001 ||
          error.response.data.code === 104
        ) {
          MessageBox.confirm('该账号异常，请重新登录', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
            type: 'warning',
          }).then(() => {
            // 清除缓存
            signOutMesssage = 0
            sessionStorage.clear()
            router.push('/login')
          })
        }
        Message({
          message: error.response.data.message,
          type: 'error',
          duration: 5 * 1000,
        })
      }
      return Promise.reject(error)
    } catch (error) {
      Message({
        message: '请求网络错误',
        type: 'error',
        duration: 5 * 1000,
      })
    }
  }
)

export default service
