<template>
  <!-- 综合体养老服务中心 -->
  <div>
    <div class="service">
      <div>
        <div class="service-title"></div>
      </div>
      <div class="service-main">
        <div class="service-old">老年人能力评估</div>
        <div class="grade">
          <div class="grade-z">
            <div class="service-img">
              <img src="../../../../image/top/zhili.png" alt="" />
            </div>
            <div class="num-text">
              <div class="grade-num">{{ selfCare }}</div>
              <div class="grade-text">自理</div>
            </div>
          </div>
          <div class="grade-j">
            <div class="service-img">
              <img src="../../../../image/top/jiezhu.png" alt="" />
            </div>
            <div class="num-text">
              <div class="grade-num">{{ mediationAssistance }}</div>
              <div class="grade-text">介助</div>
            </div>
          </div>
        </div>
        <div class="grade">
          <div class="grade-z">
            <div class="service-img">
              <img src="../../../../image/top/jiehu.png" alt="" />
            </div>
            <div class="num-text">
              <div class="grade-num">{{ interNursing }}</div>
              <div class="grade-text">介护</div>
            </div>
          </div>
          <div class="grade-j">
            <div class="service-img">
              <img src="../../../../image/top/tehu.png" alt="" />
            </div>
            <div class="num-text">
              <div class="grade-num">{{ specialCare }}</div>
              <div class="grade-text">特护</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selfCare: 0,
      mediationAssistance: 0,
      interNursing: 0,
      specialCare: 0,
    }
  },
  props: {
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const provinceName = sessionStorage.getItem('provinceName')
      const cityName = sessionStorage.getItem('cityName')
      if (this.levelAndCode) {
        if (this.levelAndCode.level == 'PROVINCE') {
          if (provinceName == '四川省') {
            this.selfCare = 77856
            this.mediationAssistance = 58392
            this.interNursing = 38928
            this.specialCare = 19464
          } else if (provinceName == '云南省') {
            this.selfCare = 16587
            this.mediationAssistance = 12440
            this.interNursing = 8293
            this.specialCare = 4147
          } else {
            this.selfCare = 0
            this.mediationAssistance = 0
            this.interNursing = 0
            this.specialCare = 0
          }
        } else if (this.levelAndCode.level == 'CITY') {
          if (cityName == '成都市') {
            this.selfCare = 30286
            this.mediationAssistance = 22714
            this.interNursing = 15143
            this.specialCare = 7571
          } else if (cityName == '达州市') {
            this.selfCare = 9650
            this.mediationAssistance = 7237
            this.interNursing = 4825
            this.specialCare = 2412
          } else if (cityName == '广安市') {
            this.selfCare = 6458
            this.mediationAssistance = 4843
            this.interNursing = 3229
            this.specialCare = 1614
          } else if (cityName == '乐山市') {
            this.selfCare = 6194
            this.mediationAssistance = 4645
            this.interNursing = 3097
            this.specialCare = 1548
          } else if (cityName == '眉山市') {
            this.selfCare = 5851
            this.mediationAssistance = 4388
            this.interNursing = 2925
            this.specialCare = 1463
          } else if (cityName == '南充市') {
            this.selfCare = 11569
            this.mediationAssistance = 8677
            this.interNursing = 5784
            this.specialCare = 2892
          } else if (cityName == '雅安市') {
            this.selfCare = 2461
            this.mediationAssistance = 1846
            this.interNursing = 1231
            this.specialCare = 615
          } else if (cityName == '自贡市') {
            this.selfCare = 5388
            this.mediationAssistance = 4041
            this.interNursing = 2694
            this.specialCare = 1347
          } else if (cityName == '红河哈尼族彝族自治州') {
            this.selfCare = 5338
            this.mediationAssistance = 4003
            this.interNursing = 2669
            this.specialCare = 1334
          } else if (cityName == '昆明市') {
            this.selfCare = 9794
            this.mediationAssistance = 7346
            this.interNursing = 4897
            this.specialCare = 2449
          } else if (cityName == '丽江市') {
            this.selfCare = 1455
            this.mediationAssistance = 1091
            this.interNursing = 727
            this.specialCare = 364
          } else {
            this.selfCare = 0
            this.mediationAssistance = 0
            this.interNursing = 0
            this.specialCare = 0
          }
        }
      } else {
        this.selfCare = 94443
        this.mediationAssistance = 70832
        this.interNursing = 47221
        this.specialCare = 23611
      }
    },
  },
}
</script>
<style scoped>
.service {
  color: #ffffff;
  background: url(../../../../image/top/fuwuzhongxing.png) 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
  position: relative;
}
.service-title {
  height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-main {
  padding: 0.8rem;
}
.service-old {
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #7fb5f6;
  font-size: 0.6rem;
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
  font-family: Source Han Sans CN;
  color: #7fb5f6;
}
.grade {
  display: flex;
  height: 2.7rem;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.grade-z,
.grade-j {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  width: 49%;
  background-size: auto;
  background: url(../../../../image/right/serviceCenter.png) no-repeat;
  background-size: 100% 100%;
}
.service-img img {
  height: 2.3rem;
  vertical-align: top;
  text-align: center;
  margin-left: 1rem;
}

.num-text {
  width: 4.5rem;
  /* text-align: center; */
  margin-left: 0.2rem;
}
.grade-num {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #ffffff;
}
.grade-text {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.8rem;
  margin-left: 0.3rem;
}
/* .left-box-top::after {
  content: '';
  width: 10%;
  height: 10%;
  position: absolute;
  right: 0;
  border-top: 2px solid rgba(0, 213, 255, 0.7);
  border-right: 2px solid rgba(0, 213, 255, 0.7);
}

.left-box-top::before {
  content: '';
  width: 10%;
  height: 10%;
  position: absolute;
  left: 0;
  border-top: 2px solid rgba(0, 213, 255, 0.7);
  border-left: 2px solid rgba(0, 213, 255, 0.7);
}
.left-box-bottom::after {
  content: '';
  width: 10%;
  height: 10%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 2px solid rgba(0, 213, 255, 0.7);
  border-right: 2px solid rgba(0, 213, 255, 0.7);
}

.left-box-bottom::before {
  content: '';
  width: 10%;
  height: 10%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid rgba(0, 213, 255, 0.7);
  border-left: 2px solid rgba(0, 213, 255, 0.7);
} */
</style>
