const cityMap = {
  北京市: '110100',
  东城区: '110101',
  西城区: '110102',
  朝阳区: '220104',
  丰台区: '110106',
  石景山区: '110107',
  海淀区: '110108',
  门头沟区: '110109',
  房山区: '110111',
  通州区: '320612',
  顺义区: '110113',
  昌平区: '110114',
  大兴区: '110115',
  怀柔区: '110116',
  平谷区: '110117',
  密云县: '110228',
  延庆县: '110229',
  天津市: '120100',
  和平区: '210102',
  河东区: '371312',
  河西区: '120103',
  南开区: '120104',
  河北区: '120105',
  红桥区: '120106',
  东丽区: '120110',
  西青区: '120111',
  津南区: '120112',
  北辰区: '120113',
  武清区: '120114',
  宝坻区: '120115',
  滨海新区: '120116',
  宁河县: '120221',
  静海县: '120223',
  蓟县: '120225',
  河北省: '130000',
  石家庄市: '130100',
  长安区: '610116',
  桥东区: '130702',
  桥西区: '130703',
  新华区: '410402',
  井陉矿区: '130107',
  裕华区: '130108',
  井陉县: '130121',
  正定县: '130123',
  栾城县: '130124',
  行唐县: '130125',
  灵寿县: '130126',
  高邑县: '130127',
  深泽县: '130128',
  赞皇县: '130129',
  无极县: '130130',
  平山县: '130131',
  元氏县: '130132',
  赵县: '130133',
  辛集市: '130181',
  藁城市: '130182',
  晋州市: '130183',
  新乐市: '130184',
  鹿泉市: '130185',
  唐山市: '130200',
  路南区: '130202',
  路北区: '130203',
  古冶区: '130204',
  开平区: '130205',
  丰南区: '130207',
  丰润区: '130208',
  曹妃甸区: '130209',
  滦县: '130223',
  滦南县: '130224',
  乐亭县: '130225',
  迁西县: '130227',
  玉田县: '130229',
  遵化市: '130281',
  迁安市: '130283',
  秦皇岛市: '130300',
  海港区: '130302',
  山海关区: '130303',
  北戴河区: '130304',
  青龙满族自治县: '130321',
  昌黎县: '130322',
  抚宁县: '130323',
  卢龙县: '130324',
  邯郸市: '130400',
  邯山区: '130402',
  丛台区: '130403',
  复兴区: '130404',
  峰峰矿区: '130406',
  邯郸县: '130421',
  临漳县: '130423',
  成安县: '130424',
  大名县: '130425',
  涉县: '130426',
  磁县: '130427',
  肥乡县: '130428',
  永年县: '130429',
  邱县: '130430',
  鸡泽县: '130431',
  广平县: '130432',
  馆陶县: '130433',
  魏县: '130434',
  曲周县: '130435',
  武安市: '130481',
  邢台市: '130500',
  邢台县: '130521',
  临城县: '130522',
  内丘县: '130523',
  柏乡县: '130524',
  隆尧县: '130525',
  任县: '130526',
  南和县: '130527',
  宁晋县: '130528',
  巨鹿县: '130529',
  新河县: '130530',
  广宗县: '130531',
  平乡县: '130532',
  威县: '130533',
  清河县: '130534',
  临西县: '130535',
  南宫市: '130581',
  沙河市: '130582',
  保定市: '130600',
  新市区: '650104',
  北市区: '130603',
  南市区: '130604',
  满城县: '130621',
  清苑县: '130622',
  涞水县: '130623',
  阜平县: '130624',
  徐水县: '130625',
  定兴县: '130626',
  唐县: '130627',
  高阳县: '130628',
  容城县: '130629',
  涞源县: '130630',
  望都县: '130631',
  安新县: '130632',
  易县: '130633',
  曲阳县: '130634',
  蠡县: '130635',
  顺平县: '130636',
  博野县: '130637',
  雄县: '130638',
  涿州市: '130681',
  定州市: '130682',
  安国市: '130683',
  高碑店市: '130684',
  张家口市: '130700',
  宣化区: '130705',
  下花园区: '130706',
  宣化县: '130721',
  张北县: '130722',
  康保县: '130723',
  沽源县: '130724',
  尚义县: '130725',
  蔚县: '130726',
  阳原县: '130727',
  怀安县: '130728',
  万全县: '130729',
  怀来县: '130730',
  涿鹿县: '130731',
  赤城县: '130732',
  崇礼县: '130733',
  承德市: '130800',
  双桥区: '130802',
  双滦区: '130803',
  鹰手营子矿区: '130804',
  承德县: '130821',
  兴隆县: '130822',
  平泉县: '130823',
  滦平县: '130824',
  隆化县: '130825',
  丰宁满族自治县: '130826',
  宽城满族自治县: '130827',
  围场满族蒙古族自治县: '130828',
  沧州市: '130900',
  运河区: '130903',
  沧县: '130921',
  青县: '130922',
  东光县: '130923',
  海兴县: '130924',
  盐山县: '130925',
  肃宁县: '130926',
  南皮县: '130927',
  吴桥县: '130928',
  献县: '130929',
  孟村回族自治县: '130930',
  泊头市: '130981',
  任丘市: '130982',
  黄骅市: '130983',
  河间市: '130984',
  廊坊市: '131000',
  安次区: '131002',
  广阳区: '131003',
  固安县: '131022',
  永清县: '131023',
  香河县: '131024',
  大城县: '131025',
  文安县: '131026',
  大厂回族自治县: '131028',
  霸州市: '131081',
  三河市: '131082',
  衡水市: '131100',
  桃城区: '131102',
  枣强县: '131121',
  武邑县: '131122',
  武强县: '131123',
  饶阳县: '131124',
  安平县: '131125',
  故城县: '131126',
  景县: '131127',
  阜城县: '131128',
  冀州市: '131181',
  深州市: '131182',
  山西省: '140000',
  太原市: '140100',
  小店区: '140105',
  迎泽区: '140106',
  杏花岭区: '140107',
  尖草坪区: '140108',
  万柏林区: '140109',
  晋源区: '140110',
  清徐县: '140121',
  阳曲县: '140122',
  娄烦县: '140123',
  古交市: '140181',
  大同市: '140200',
  城区: '441502',
  矿区: '140303',
  南郊区: '140211',
  新荣区: '140212',
  阳高县: '140221',
  天镇县: '140222',
  广灵县: '140223',
  灵丘县: '140224',
  浑源县: '140225',
  左云县: '140226',
  大同县: '140227',
  阳泉市: '140300',
  郊区: '340711',
  平定县: '140321',
  盂县: '140322',
  长治市: '140400',
  长治县: '140421',
  襄垣县: '140423',
  屯留县: '140424',
  平顺县: '140425',
  黎城县: '140426',
  壶关县: '140427',
  长子县: '140428',
  武乡县: '140429',
  沁县: '140430',
  沁源县: '140431',
  潞城市: '140481',
  晋城市: '140500',
  沁水县: '140521',
  阳城县: '140522',
  陵川县: '140524',
  泽州县: '140525',
  高平市: '140581',
  朔州市: '140600',
  朔城区: '140602',
  平鲁区: '140603',
  山阴县: '140621',
  应县: '140622',
  右玉县: '140623',
  怀仁县: '140624',
  晋中市: '140700',
  榆次区: '140702',
  榆社县: '140721',
  左权县: '140722',
  和顺县: '140723',
  昔阳县: '140724',
  寿阳县: '140725',
  太谷县: '140726',
  祁县: '140727',
  平遥县: '140728',
  灵石县: '140729',
  介休市: '140781',
  运城市: '140800',
  盐湖区: '140802',
  临猗县: '140821',
  万荣县: '140822',
  闻喜县: '140823',
  稷山县: '140824',
  新绛县: '140825',
  绛县: '140826',
  垣曲县: '140827',
  夏县: '140828',
  平陆县: '140829',
  芮城县: '140830',
  永济市: '140881',
  河津市: '140882',
  忻州市: '140900',
  忻府区: '140902',
  定襄县: '140921',
  五台县: '140922',
  代县: '140923',
  繁峙县: '140924',
  宁武县: '140925',
  静乐县: '140926',
  神池县: '140927',
  五寨县: '140928',
  岢岚县: '140929',
  河曲县: '140930',
  保德县: '140931',
  偏关县: '140932',
  原平市: '140981',
  临汾市: '141000',
  尧都区: '141002',
  曲沃县: '141021',
  翼城县: '141022',
  襄汾县: '141023',
  洪洞县: '141024',
  古县: '141025',
  安泽县: '141026',
  浮山县: '141027',
  吉县: '141028',
  乡宁县: '141029',
  大宁县: '141030',
  隰县: '141031',
  永和县: '141032',
  蒲县: '141033',
  汾西县: '141034',
  侯马市: '141081',
  霍州市: '141082',
  吕梁市: '141100',
  离石区: '141102',
  文水县: '141121',
  交城县: '141122',
  兴县: '141123',
  临县: '141124',
  柳林县: '141125',
  石楼县: '141126',
  岚县: '141127',
  方山县: '141128',
  中阳县: '141129',
  交口县: '141130',
  孝义市: '141181',
  汾阳市: '141182',
  内蒙古自治区: '150000',
  呼和浩特市: '150100',
  新城区: '610102',
  回民区: '150103',
  玉泉区: '150104',
  赛罕区: '150105',
  土默特左旗: '150121',
  托克托县: '150122',
  和林格尔县: '150123',
  清水河县: '150124',
  武川县: '150125',
  包头市: '150200',
  东河区: '150202',
  昆都仑区: '150203',
  青山区: '420107',
  石拐区: '150205',
  白云鄂博矿区: '150206',
  九原区: '150207',
  土默特右旗: '150221',
  固阳县: '150222',
  达尔罕茂明安联合旗: '150223',
  乌海市: '150300',
  海勃湾区: '150302',
  海南区: '150303',
  乌达区: '150304',
  赤峰市: '150400',
  红山区: '150402',
  元宝山区: '150403',
  松山区: '150404',
  阿鲁科尔沁旗: '150421',
  巴林左旗: '150422',
  巴林右旗: '150423',
  林西县: '150424',
  克什克腾旗: '150425',
  翁牛特旗: '150426',
  喀喇沁旗: '150428',
  宁城县: '150429',
  敖汉旗: '150430',
  通辽市: '150500',
  科尔沁区: '150502',
  科尔沁左翼中旗: '150521',
  科尔沁左翼后旗: '150522',
  开鲁县: '150523',
  库伦旗: '150524',
  奈曼旗: '150525',
  扎鲁特旗: '150526',
  霍林郭勒市: '150581',
  鄂尔多斯市: '150600',
  东胜区: '150602',
  达拉特旗: '150621',
  准格尔旗: '150622',
  鄂托克前旗: '150623',
  鄂托克旗: '150624',
  杭锦旗: '150625',
  乌审旗: '150626',
  伊金霍洛旗: '150627',
  呼伦贝尔市: '150700',
  海拉尔区: '150702',
  扎赉诺尔区: '150703',
  阿荣旗: '150721',
  莫力达瓦达斡尔族自治旗: '150722',
  鄂伦春自治旗: '150723',
  鄂温克族自治旗: '150724',
  陈巴尔虎旗: '150725',
  新巴尔虎左旗: '150726',
  新巴尔虎右旗: '150727',
  满洲里市: '150781',
  牙克石市: '150782',
  扎兰屯市: '150783',
  额尔古纳市: '150784',
  根河市: '150785',
  巴彦淖尔市: '150800',
  临河区: '150802',
  五原县: '150821',
  磴口县: '150822',
  乌拉特前旗: '150823',
  乌拉特中旗: '150824',
  乌拉特后旗: '150825',
  杭锦后旗: '150826',
  乌兰察布市: '150900',
  集宁区: '150902',
  卓资县: '150921',
  化德县: '150922',
  商都县: '150923',
  兴和县: '150924',
  凉城县: '150925',
  察哈尔右翼前旗: '150926',
  察哈尔右翼中旗: '150927',
  察哈尔右翼后旗: '150928',
  四子王旗: '150929',
  丰镇市: '150981',
  兴安盟: '152200',
  乌兰浩特市: '152201',
  阿尔山市: '152202',
  科尔沁右翼前旗: '152221',
  科尔沁右翼中旗: '152222',
  扎赉特旗: '152223',
  突泉县: '152224',
  锡林郭勒盟: '152500',
  二连浩特市: '152501',
  锡林浩特市: '152502',
  阿巴嘎旗: '152522',
  苏尼特左旗: '152523',
  苏尼特右旗: '152524',
  东乌珠穆沁旗: '152525',
  西乌珠穆沁旗: '152526',
  太仆寺旗: '152527',
  镶黄旗: '152528',
  正镶白旗: '152529',
  正蓝旗: '152530',
  多伦县: '152531',
  阿拉善盟: '152900',
  阿拉善左旗: '152921',
  阿拉善右旗: '152922',
  额济纳旗: '152923',
  辽宁省: '210000',
  沈阳市: '210100',
  沈河区: '210103',
  大东区: '210104',
  皇姑区: '210105',
  铁西区: '220302',
  苏家屯区: '210111',
  东陵区: '210112',
  沈北新区: '210113',
  于洪区: '210114',
  辽中县: '210122',
  康平县: '210123',
  法库县: '210124',
  新民市: '210181',
  大连市: '210200',
  中山区: '210202',
  西岗区: '210203',
  沙河口区: '210204',
  甘井子区: '210211',
  旅顺口区: '210212',
  金州区: '210213',
  长海县: '210224',
  瓦房店市: '210281',
  普兰店市: '210282',
  庄河市: '210283',
  鞍山市: '210300',
  铁东区: '220303',
  立山区: '210304',
  千山区: '210311',
  台安县: '210321',
  岫岩满族自治县: '210323',
  海城市: '210381',
  抚顺市: '210400',
  新抚区: '210402',
  东洲区: '210403',
  望花区: '210404',
  顺城区: '210411',
  抚顺县: '210421',
  新宾满族自治县: '210422',
  清原满族自治县: '210423',
  本溪市: '210500',
  平山区: '210502',
  溪湖区: '210503',
  明山区: '210504',
  南芬区: '210505',
  本溪满族自治县: '210521',
  桓仁满族自治县: '210522',
  丹东市: '210600',
  元宝区: '210602',
  振兴区: '210603',
  振安区: '210604',
  宽甸满族自治县: '210624',
  东港市: '210681',
  凤城市: '210682',
  锦州市: '210700',
  古塔区: '210702',
  凌河区: '210703',
  太和区: '210711',
  黑山县: '210726',
  义县: '210727',
  凌海市: '210781',
  北镇市: '210782',
  营口市: '210800',
  站前区: '210802',
  西市区: '210803',
  鲅鱼圈区: '210804',
  老边区: '210811',
  盖州市: '210881',
  大石桥市: '210882',
  阜新市: '210900',
  海州区: '320706',
  新邱区: '210903',
  太平区: '210904',
  清河门区: '210905',
  细河区: '210911',
  阜新蒙古族自治县: '210921',
  彰武县: '210922',
  辽阳市: '211000',
  白塔区: '211002',
  文圣区: '211003',
  宏伟区: '211004',
  弓长岭区: '211005',
  太子河区: '211011',
  辽阳县: '211021',
  灯塔市: '211081',
  盘锦市: '211100',
  双台子区: '211102',
  兴隆台区: '211103',
  大洼县: '211121',
  盘山县: '211122',
  铁岭市: '211200',
  银州区: '211202',
  清河区: '320802',
  铁岭县: '211221',
  西丰县: '211223',
  昌图县: '211224',
  调兵山市: '211281',
  开原市: '211282',
  朝阳市: '211300',
  双塔区: '211302',
  龙城区: '211303',
  朝阳县: '211321',
  建平县: '211322',
  喀喇沁左翼蒙古族自治县: '211324',
  北票市: '211381',
  凌源市: '211382',
  葫芦岛市: '211400',
  连山区: '211402',
  龙港区: '211403',
  南票区: '211404',
  绥中县: '211421',
  建昌县: '211422',
  兴城市: '211481',
  吉林省: '220000',
  长春市: '220100',
  南关区: '220102',
  宽城区: '220103',
  二道区: '220105',
  绿园区: '220106',
  双阳区: '220112',
  农安县: '220122',
  九台市: '220181',
  榆树市: '220182',
  德惠市: '220183',
  吉林市: '220200',
  昌邑区: '220202',
  龙潭区: '220203',
  船营区: '220204',
  丰满区: '220211',
  永吉县: '220221',
  蛟河市: '220281',
  桦甸市: '220282',
  舒兰市: '220283',
  磐石市: '220284',
  四平市: '220300',
  梨树县: '220322',
  伊通满族自治县: '220323',
  公主岭市: '220381',
  双辽市: '220382',
  辽源市: '220400',
  龙山区: '220402',
  西安区: '231005',
  东丰县: '220421',
  东辽县: '220422',
  通化市: '220500',
  东昌区: '220502',
  二道江区: '220503',
  通化县: '220521',
  辉南县: '220523',
  柳河县: '220524',
  梅河口市: '220581',
  集安市: '220582',
  白山市: '220600',
  浑江区: '220602',
  江源区: '220605',
  抚松县: '220621',
  靖宇县: '220622',
  长白朝鲜族自治县: '220623',
  临江市: '220681',
  松原市: '220700',
  宁江区: '220702',
  前郭尔罗斯蒙古族自治县: '220721',
  长岭县: '220722',
  乾安县: '220723',
  扶余市: '220781',
  白城市: '220800',
  洮北区: '220802',
  镇赉县: '220821',
  通榆县: '220822',
  洮南市: '220881',
  大安市: '220882',
  延边朝鲜族自治州: '222400',
  延吉市: '222401',
  图们市: '222402',
  敦化市: '222403',
  珲春市: '222404',
  龙井市: '222405',
  和龙市: '222406',
  汪清县: '222424',
  安图县: '222426',
  黑龙江省: '230000',
  哈尔滨市: '230100',
  道里区: '230102',
  南岗区: '230103',
  道外区: '230104',
  平房区: '230108',
  松北区: '230109',
  香坊区: '230110',
  呼兰区: '230111',
  阿城区: '230112',
  依兰县: '230123',
  方正县: '230124',
  宾县: '230125',
  巴彦县: '230126',
  木兰县: '230127',
  通河县: '230128',
  延寿县: '230129',
  双城市: '230182',
  尚志市: '230183',
  五常市: '230184',
  齐齐哈尔市: '230200',
  龙沙区: '230202',
  建华区: '230203',
  铁锋区: '230204',
  昂昂溪区: '230205',
  富拉尔基区: '230206',
  碾子山区: '230207',
  梅里斯达斡尔族区: '230208',
  龙江县: '230221',
  依安县: '230223',
  泰来县: '230224',
  甘南县: '230225',
  富裕县: '230227',
  克山县: '230229',
  克东县: '230230',
  拜泉县: '230231',
  讷河市: '230281',
  鸡西市: '230300',
  鸡冠区: '230302',
  恒山区: '230303',
  滴道区: '230304',
  梨树区: '230305',
  城子河区: '230306',
  麻山区: '230307',
  鸡东县: '230321',
  虎林市: '230381',
  密山市: '230382',
  鹤岗市: '230400',
  向阳区: '230803',
  工农区: '230403',
  南山区: '440305',
  兴安区: '230405',
  东山区: '230406',
  兴山区: '230407',
  萝北县: '230421',
  绥滨县: '230422',
  双鸭山市: '230500',
  尖山区: '230502',
  岭东区: '230503',
  四方台区: '230505',
  宝山区: '310113',
  集贤县: '230521',
  友谊县: '230522',
  宝清县: '230523',
  饶河县: '230524',
  大庆市: '230600',
  萨尔图区: '230602',
  龙凤区: '230603',
  让胡路区: '230604',
  红岗区: '230605',
  大同区: '230606',
  肇州县: '230621',
  肇源县: '230622',
  林甸县: '230623',
  杜尔伯特蒙古族自治县: '230624',
  伊春市: '230700',
  伊春区: '230702',
  南岔区: '230703',
  友好区: '230704',
  西林区: '230705',
  翠峦区: '230706',
  新青区: '230707',
  美溪区: '230708',
  金山屯区: '230709',
  五营区: '230710',
  乌马河区: '230711',
  汤旺河区: '230712',
  带岭区: '230713',
  乌伊岭区: '230714',
  红星区: '230715',
  上甘岭区: '230716',
  嘉荫县: '230722',
  铁力市: '230781',
  佳木斯市: '230800',
  前进区: '230804',
  东风区: '230805',
  桦南县: '230822',
  桦川县: '230826',
  汤原县: '230828',
  抚远县: '230833',
  同江市: '230881',
  富锦市: '230882',
  七台河市: '230900',
  新兴区: '230902',
  桃山区: '230903',
  茄子河区: '230904',
  勃利县: '230921',
  牡丹江市: '231000',
  东安区: '231002',
  阳明区: '231003',
  爱民区: '231004',
  东宁县: '231024',
  林口县: '231025',
  绥芬河市: '231081',
  海林市: '231083',
  宁安市: '231084',
  穆棱市: '231085',
  黑河市: '231100',
  爱辉区: '231102',
  嫩江县: '231121',
  逊克县: '231123',
  孙吴县: '231124',
  北安市: '231181',
  五大连池市: '231182',
  绥化市: '231200',
  北林区: '231202',
  望奎县: '231221',
  兰西县: '231222',
  青冈县: '231223',
  庆安县: '231224',
  明水县: '231225',
  绥棱县: '231226',
  安达市: '231281',
  肇东市: '231282',
  海伦市: '231283',
  大兴安岭地区: '232700',
  呼玛县: '232721',
  塔河县: '232722',
  漠河县: '232723',
  上海市: '310100',
  黄浦区: '310101',
  徐汇区: '310104',
  长宁区: '310105',
  静安区: '310106',
  普陀区: '330903',
  闸北区: '310108',
  虹口区: '310109',
  杨浦区: '310110',
  闵行区: '310112',
  嘉定区: '310114',
  浦东新区: '310115',
  金山区: '310116',
  松江区: '310117',
  青浦区: '310118',
  奉贤区: '310120',
  崇明县: '310230',
  江苏省: '320000',
  南京市: '320100',
  玄武区: '320102',
  秦淮区: '320104',
  建邺区: '320105',
  鼓楼区: '410204',
  浦口区: '320111',
  栖霞区: '320113',
  雨花台区: '320114',
  江宁区: '320115',
  六合区: '320116',
  溧水区: '320117',
  高淳区: '320118',
  无锡市: '320200',
  崇安区: '320202',
  南长区: '320203',
  北塘区: '320204',
  锡山区: '320205',
  惠山区: '320206',
  滨湖区: '320211',
  江阴市: '320281',
  宜兴市: '320282',
  徐州市: '320300',
  云龙区: '320303',
  贾汪区: '320305',
  泉山区: '320311',
  铜山区: '320312',
  丰县: '320321',
  沛县: '320322',
  睢宁县: '320324',
  新沂市: '320381',
  邳州市: '320382',
  常州市: '320400',
  天宁区: '320402',
  钟楼区: '320404',
  戚墅堰区: '320405',
  新北区: '320411',
  武进区: '320412',
  溧阳市: '320481',
  金坛市: '320482',
  苏州市: '320500',
  虎丘区: '320505',
  吴中区: '320506',
  相城区: '320507',
  姑苏区: '320508',
  吴江区: '320509',
  常熟市: '320581',
  张家港市: '320582',
  昆山市: '320583',
  太仓市: '320585',
  南通市: '320600',
  崇川区: '320602',
  港闸区: '320611',
  海安县: '320621',
  如东县: '320623',
  启东市: '320681',
  如皋市: '320682',
  海门市: '320684',
  连云港市: '320700',
  连云区: '320703',
  新浦区: '320705',
  赣榆县: '320721',
  东海县: '320722',
  灌云县: '320723',
  灌南县: '320724',
  淮安市: '320800',
  淮安区: '320803',
  淮阴区: '320804',
  清浦区: '320811',
  涟水县: '320826',
  洪泽县: '320829',
  盱眙县: '320830',
  金湖县: '320831',
  盐城市: '320900',
  亭湖区: '320902',
  盐都区: '320903',
  响水县: '320921',
  滨海县: '320922',
  阜宁县: '320923',
  射阳县: '320924',
  建湖县: '320925',
  东台市: '320981',
  大丰市: '320982',
  扬州市: '321000',
  广陵区: '321002',
  邗江区: '321003',
  江都区: '321012',
  宝应县: '321023',
  仪征市: '321081',
  高邮市: '321084',
  镇江市: '321100',
  京口区: '321102',
  润州区: '321111',
  丹徒区: '321112',
  丹阳市: '321181',
  扬中市: '321182',
  句容市: '321183',
  泰州市: '321200',
  海陵区: '321202',
  高港区: '321203',
  姜堰区: '321204',
  兴化市: '321281',
  靖江市: '321282',
  泰兴市: '321283',
  宿迁市: '321300',
  宿城区: '321302',
  宿豫区: '321311',
  沭阳县: '321322',
  泗阳县: '321323',
  泗洪县: '321324',
  浙江省: '330000',
  杭州市: '330100',
  上城区: '330102',
  下城区: '330103',
  江干区: '330104',
  拱墅区: '330105',
  西湖区: '360103',
  滨江区: '330108',
  萧山区: '330109',
  余杭区: '330110',
  桐庐县: '330122',
  淳安县: '330127',
  建德市: '330182',
  富阳市: '330183',
  临安市: '330185',
  宁波市: '330200',
  海曙区: '330203',
  江东区: '330204',
  江北区: '500105',
  北仑区: '330206',
  镇海区: '330211',
  鄞州区: '330212',
  象山县: '330225',
  宁海县: '330226',
  余姚市: '330281',
  慈溪市: '330282',
  奉化市: '330283',
  温州市: '330300',
  鹿城区: '330302',
  龙湾区: '330303',
  瓯海区: '330304',
  洞头县: '330322',
  永嘉县: '330324',
  平阳县: '330326',
  苍南县: '330327',
  文成县: '330328',
  泰顺县: '330329',
  瑞安市: '330381',
  乐清市: '330382',
  嘉兴市: '330400',
  南湖区: '330402',
  秀洲区: '330411',
  嘉善县: '330421',
  海盐县: '330424',
  海宁市: '330481',
  平湖市: '330482',
  桐乡市: '330483',
  湖州市: '330500',
  吴兴区: '330502',
  南浔区: '330503',
  德清县: '330521',
  长兴县: '330522',
  安吉县: '330523',
  绍兴市: '330600',
  越城区: '330602',
  绍兴县: '330621',
  新昌县: '330624',
  诸暨市: '330681',
  上虞市: '330682',
  嵊州市: '330683',
  金华市: '330700',
  婺城区: '330702',
  金东区: '330703',
  武义县: '330723',
  浦江县: '330726',
  磐安县: '330727',
  兰溪市: '330781',
  义乌市: '330782',
  东阳市: '330783',
  永康市: '330784',
  衢州市: '330800',
  柯城区: '330802',
  衢江区: '330803',
  常山县: '330822',
  开化县: '330824',
  龙游县: '330825',
  江山市: '330881',
  舟山市: '330900',
  定海区: '330902',
  岱山县: '330921',
  嵊泗县: '330922',
  台州市: '331000',
  椒江区: '331002',
  黄岩区: '331003',
  路桥区: '331004',
  玉环县: '331021',
  三门县: '331022',
  天台县: '331023',
  仙居县: '331024',
  温岭市: '331081',
  临海市: '331082',
  丽水市: '331100',
  莲都区: '331102',
  青田县: '331121',
  缙云县: '331122',
  遂昌县: '331123',
  松阳县: '331124',
  云和县: '331125',
  庆元县: '331126',
  景宁畲族自治县: '331127',
  龙泉市: '331181',
  安徽省: '340000',
  合肥市: '340100',
  瑶海区: '340102',
  庐阳区: '340103',
  蜀山区: '340104',
  包河区: '340111',
  长丰县: '340121',
  肥东县: '340122',
  肥西县: '340123',
  庐江县: '340124',
  巢湖市: '340181',
  芜湖市: '340200',
  镜湖区: '340202',
  弋江区: '340203',
  鸠江区: '340207',
  三山区: '340208',
  芜湖县: '340221',
  繁昌县: '340222',
  南陵县: '340223',
  无为县: '340225',
  蚌埠市: '340300',
  龙子湖区: '340302',
  蚌山区: '340303',
  禹会区: '340304',
  淮上区: '340311',
  怀远县: '340321',
  五河县: '340322',
  固镇县: '340323',
  淮南市: '340400',
  大通区: '340402',
  田家庵区: '340403',
  谢家集区: '340404',
  八公山区: '340405',
  潘集区: '340406',
  凤台县: '340421',
  马鞍山市: '340500',
  花山区: '340503',
  雨山区: '340504',
  博望区: '340506',
  当涂县: '340521',
  含山县: '340522',
  和县: '340523',
  淮北市: '340600',
  杜集区: '340602',
  相山区: '340603',
  烈山区: '340604',
  濉溪县: '340621',
  铜陵市: '340700',
  铜官山区: '340702',
  狮子山区: '340703',
  铜陵县: '340721',
  安庆市: '340800',
  迎江区: '340802',
  大观区: '340803',
  宜秀区: '340811',
  怀宁县: '340822',
  枞阳县: '340823',
  潜山县: '340824',
  太湖县: '340825',
  宿松县: '340826',
  望江县: '340827',
  岳西县: '340828',
  桐城市: '340881',
  黄山市: '341000',
  屯溪区: '341002',
  黄山区: '341003',
  徽州区: '341004',
  歙县: '341021',
  休宁县: '341022',
  黟县: '341023',
  祁门县: '341024',
  滁州市: '341100',
  琅琊区: '341102',
  南谯区: '341103',
  来安县: '341122',
  全椒县: '341124',
  定远县: '341125',
  凤阳县: '341126',
  天长市: '341181',
  明光市: '341182',
  阜阳市: '341200',
  颍州区: '341202',
  颍东区: '341203',
  颍泉区: '341204',
  临泉县: '341221',
  太和县: '341222',
  阜南县: '341225',
  颍上县: '341226',
  界首市: '341282',
  宿州市: '341300',
  埇桥区: '341302',
  砀山县: '341321',
  萧县: '341322',
  灵璧县: '341323',
  泗县: '341324',
  六安市: '341500',
  金安区: '341502',
  裕安区: '341503',
  寿县: '341521',
  霍邱县: '341522',
  舒城县: '341523',
  金寨县: '341524',
  霍山县: '341525',
  亳州市: '341600',
  谯城区: '341602',
  涡阳县: '341621',
  蒙城县: '341622',
  利辛县: '341623',
  池州市: '341700',
  贵池区: '341702',
  东至县: '341721',
  石台县: '341722',
  青阳县: '341723',
  宣城市: '341800',
  宣州区: '341802',
  郎溪县: '341821',
  广德县: '341822',
  泾县: '341823',
  绩溪县: '341824',
  旌德县: '341825',
  宁国市: '341881',
  福建省: '350000',
  福州市: '350100',
  台江区: '350103',
  仓山区: '350104',
  马尾区: '350105',
  晋安区: '350111',
  闽侯县: '350121',
  连江县: '350122',
  罗源县: '350123',
  闽清县: '350124',
  永泰县: '350125',
  平潭县: '350128',
  福清市: '350181',
  长乐市: '350182',
  厦门市: '350200',
  思明区: '350203',
  海沧区: '350205',
  湖里区: '350206',
  集美区: '350211',
  同安区: '350212',
  翔安区: '350213',
  莆田市: '350300',
  城厢区: '350302',
  涵江区: '350303',
  荔城区: '350304',
  秀屿区: '350305',
  仙游县: '350322',
  三明市: '350400',
  梅列区: '350402',
  三元区: '350403',
  明溪县: '350421',
  清流县: '350423',
  宁化县: '350424',
  大田县: '350425',
  尤溪县: '350426',
  沙县: '350427',
  将乐县: '350428',
  泰宁县: '350429',
  建宁县: '350430',
  永安市: '350481',
  泉州市: '350500',
  鲤城区: '350502',
  丰泽区: '350503',
  洛江区: '350504',
  泉港区: '350505',
  惠安县: '350521',
  安溪县: '350524',
  永春县: '350525',
  德化县: '350526',
  金门县: '350527',
  石狮市: '350581',
  晋江市: '350582',
  南安市: '350583',
  漳州市: '350600',
  芗城区: '350602',
  龙文区: '350603',
  云霄县: '350622',
  漳浦县: '350623',
  诏安县: '350624',
  长泰县: '350625',
  东山县: '350626',
  南靖县: '350627',
  平和县: '350628',
  华安县: '350629',
  龙海市: '350681',
  南平市: '350700',
  延平区: '350702',
  顺昌县: '350721',
  浦城县: '350722',
  光泽县: '350723',
  松溪县: '350724',
  政和县: '350725',
  邵武市: '350781',
  武夷山市: '350782',
  建瓯市: '350783',
  建阳市: '350784',
  龙岩市: '350800',
  新罗区: '350802',
  长汀县: '350821',
  永定县: '350822',
  上杭县: '350823',
  武平县: '350824',
  连城县: '350825',
  漳平市: '350881',
  宁德市: '350900',
  蕉城区: '350902',
  霞浦县: '350921',
  古田县: '350922',
  屏南县: '350923',
  寿宁县: '350924',
  周宁县: '350925',
  柘荣县: '350926',
  福安市: '350981',
  福鼎市: '350982',
  江西省: '360000',
  南昌市: '360100',
  东湖区: '360102',
  青云谱区: '360104',
  湾里区: '360105',
  青山湖区: '360111',
  南昌县: '360121',
  新建县: '360122',
  安义县: '360123',
  进贤县: '360124',
  景德镇市: '360200',
  昌江区: '360202',
  珠山区: '360203',
  浮梁县: '360222',
  乐平市: '360281',
  萍乡市: '360300',
  安源区: '360302',
  湘东区: '360313',
  莲花县: '360321',
  上栗县: '360322',
  芦溪县: '360323',
  九江市: '360400',
  庐山区: '360402',
  浔阳区: '360403',
  九江县: '360421',
  武宁县: '360423',
  修水县: '360424',
  永修县: '360425',
  德安县: '360426',
  星子县: '360427',
  都昌县: '360428',
  湖口县: '360429',
  彭泽县: '360430',
  瑞昌市: '360481',
  共青城市: '360482',
  新余市: '360500',
  渝水区: '360502',
  分宜县: '360521',
  鹰潭市: '360600',
  月湖区: '360602',
  余江县: '360622',
  贵溪市: '360681',
  赣州市: '360700',
  章贡区: '360702',
  赣县: '360721',
  信丰县: '360722',
  大余县: '360723',
  上犹县: '360724',
  崇义县: '360725',
  安远县: '360726',
  龙南县: '360727',
  定南县: '360728',
  全南县: '360729',
  宁都县: '360730',
  于都县: '360731',
  兴国县: '360732',
  会昌县: '360733',
  寻乌县: '360734',
  石城县: '360735',
  瑞金市: '360781',
  南康市: '360782',
  吉安市: '360800',
  吉州区: '360802',
  青原区: '360803',
  吉安县: '360821',
  吉水县: '360822',
  峡江县: '360823',
  新干县: '360824',
  永丰县: '360825',
  泰和县: '360826',
  遂川县: '360827',
  万安县: '360828',
  安福县: '360829',
  永新县: '360830',
  井冈山市: '360881',
  宜春市: '360900',
  袁州区: '360902',
  奉新县: '360921',
  万载县: '360922',
  上高县: '360923',
  宜丰县: '360924',
  靖安县: '360925',
  铜鼓县: '360926',
  丰城市: '360981',
  樟树市: '360982',
  高安市: '360983',
  抚州市: '361000',
  临川区: '361002',
  南城县: '361021',
  黎川县: '361022',
  南丰县: '361023',
  崇仁县: '361024',
  乐安县: '361025',
  宜黄县: '361026',
  金溪县: '361027',
  资溪县: '361028',
  东乡县: '361029',
  广昌县: '361030',
  上饶市: '361100',
  信州区: '361102',
  上饶县: '361121',
  广丰县: '361122',
  玉山县: '361123',
  铅山县: '361124',
  横峰县: '361125',
  弋阳县: '361126',
  余干县: '361127',
  鄱阳县: '361128',
  万年县: '361129',
  婺源县: '361130',
  德兴市: '361181',
  山东省: '370000',
  济南市: '370100',
  历下区: '370102',
  市中区: '511102',
  槐荫区: '370104',
  天桥区: '370105',
  历城区: '370112',
  长清区: '370113',
  平阴县: '370124',
  济阳县: '370125',
  商河县: '370126',
  章丘市: '370181',
  青岛市: '370200',
  市南区: '370202',
  市北区: '370203',
  黄岛区: '370211',
  崂山区: '370212',
  李沧区: '370213',
  城阳区: '370214',
  胶州市: '370281',
  即墨市: '370282',
  平度市: '370283',
  莱西市: '370285',
  淄博市: '370300',
  淄川区: '370302',
  张店区: '370303',
  博山区: '370304',
  临淄区: '370305',
  周村区: '370306',
  桓台县: '370321',
  高青县: '370322',
  沂源县: '370323',
  枣庄市: '370400',
  薛城区: '370403',
  峄城区: '370404',
  台儿庄区: '370405',
  山亭区: '370406',
  滕州市: '370481',
  东营市: '370500',
  东营区: '370502',
  河口区: '370503',
  垦利县: '370521',
  利津县: '370522',
  广饶县: '370523',
  烟台市: '370600',
  芝罘区: '370602',
  福山区: '370611',
  牟平区: '370612',
  莱山区: '370613',
  长岛县: '370634',
  龙口市: '370681',
  莱阳市: '370682',
  莱州市: '370683',
  蓬莱市: '370684',
  招远市: '370685',
  栖霞市: '370686',
  海阳市: '370687',
  潍坊市: '370700',
  潍城区: '370702',
  寒亭区: '370703',
  坊子区: '370704',
  奎文区: '370705',
  临朐县: '370724',
  昌乐县: '370725',
  青州市: '370781',
  诸城市: '370782',
  寿光市: '370783',
  安丘市: '370784',
  高密市: '370785',
  昌邑市: '370786',
  济宁市: '370800',
  任城区: '370811',
  微山县: '370826',
  鱼台县: '370827',
  金乡县: '370828',
  嘉祥县: '370829',
  汶上县: '370830',
  泗水县: '370831',
  梁山县: '370832',
  曲阜市: '370881',
  兖州市: '370882',
  邹城市: '370883',
  泰安市: '370900',
  泰山区: '370902',
  岱岳区: '370911',
  宁阳县: '370921',
  东平县: '370923',
  新泰市: '370982',
  肥城市: '370983',
  威海市: '371000',
  环翠区: '371002',
  文登市: '371081',
  荣成市: '371082',
  乳山市: '371083',
  日照市: '371100',
  东港区: '371102',
  岚山区: '371103',
  五莲县: '371121',
  莒县: '371122',
  莱芜市: '371200',
  莱城区: '371202',
  钢城区: '371203',
  临沂市: '371300',
  兰山区: '371302',
  罗庄区: '371311',
  沂南县: '371321',
  郯城县: '371322',
  沂水县: '371323',
  苍山县: '371324',
  费县: '371325',
  平邑县: '371326',
  莒南县: '371327',
  蒙阴县: '371328',
  临沭县: '371329',
  德州市: '371400',
  德城区: '371402',
  陵县: '371421',
  宁津县: '371422',
  庆云县: '371423',
  临邑县: '371424',
  齐河县: '371425',
  平原县: '371426',
  夏津县: '371427',
  武城县: '371428',
  乐陵市: '371481',
  禹城市: '371482',
  聊城市: '371500',
  东昌府区: '371502',
  阳谷县: '371521',
  莘县: '371522',
  茌平县: '371523',
  东阿县: '371524',
  冠县: '371525',
  高唐县: '371526',
  临清市: '371581',
  滨州市: '371600',
  滨城区: '371602',
  惠民县: '371621',
  阳信县: '371622',
  无棣县: '371623',
  沾化县: '371624',
  博兴县: '371625',
  邹平县: '371626',
  菏泽市: '371700',
  牡丹区: '371702',
  曹县: '371721',
  单县: '371722',
  成武县: '371723',
  巨野县: '371724',
  郓城县: '371725',
  鄄城县: '371726',
  定陶县: '371727',
  东明县: '371728',
  河南省: '410000',
  郑州市: '410100',
  中原区: '410102',
  二七区: '410103',
  管城回族区: '410104',
  金水区: '410105',
  上街区: '410106',
  惠济区: '410108',
  中牟县: '410122',
  巩义市: '410181',
  荥阳市: '410182',
  新密市: '410183',
  新郑市: '410184',
  登封市: '410185',
  开封市: '410200',
  龙亭区: '410202',
  顺河回族区: '410203',
  禹王台区: '410205',
  金明区: '410211',
  杞县: '410221',
  通许县: '410222',
  尉氏县: '410223',
  开封县: '410224',
  兰考县: '410225',
  洛阳市: '410300',
  老城区: '410302',
  西工区: '410303',
  瀍河回族区: '410304',
  涧西区: '410305',
  吉利区: '410306',
  洛龙区: '410311',
  孟津县: '410322',
  新安县: '410323',
  栾川县: '410324',
  嵩县: '410325',
  汝阳县: '410326',
  宜阳县: '410327',
  洛宁县: '410328',
  伊川县: '410329',
  偃师市: '410381',
  平顶山市: '410400',
  卫东区: '410403',
  石龙区: '410404',
  湛河区: '410411',
  宝丰县: '410421',
  叶县: '410422',
  鲁山县: '410423',
  郏县: '410425',
  舞钢市: '410481',
  汝州市: '410482',
  安阳市: '410500',
  文峰区: '410502',
  北关区: '410503',
  殷都区: '410505',
  龙安区: '410506',
  安阳县: '410522',
  汤阴县: '410523',
  滑县: '410526',
  内黄县: '410527',
  林州市: '410581',
  鹤壁市: '410600',
  鹤山区: '410602',
  山城区: '410603',
  淇滨区: '410611',
  浚县: '410621',
  淇县: '410622',
  新乡市: '410700',
  红旗区: '410702',
  卫滨区: '410703',
  凤泉区: '410704',
  牧野区: '410711',
  新乡县: '410721',
  获嘉县: '410724',
  原阳县: '410725',
  延津县: '410726',
  封丘县: '410727',
  长垣县: '410728',
  卫辉市: '410781',
  辉县市: '410782',
  焦作市: '410800',
  解放区: '410802',
  中站区: '410803',
  马村区: '410804',
  山阳区: '410811',
  修武县: '410821',
  博爱县: '410822',
  武陟县: '410823',
  温县: '410825',
  沁阳市: '410882',
  孟州市: '410883',
  濮阳市: '410900',
  华龙区: '410902',
  清丰县: '410922',
  南乐县: '410923',
  范县: '410926',
  台前县: '410927',
  濮阳县: '410928',
  许昌市: '411000',
  魏都区: '411002',
  许昌县: '411023',
  鄢陵县: '411024',
  襄城县: '411025',
  禹州市: '411081',
  长葛市: '411082',
  漯河市: '411100',
  源汇区: '411102',
  郾城区: '411103',
  召陵区: '411104',
  舞阳县: '411121',
  临颍县: '411122',
  三门峡市: '411200',
  湖滨区: '411202',
  渑池县: '411221',
  陕县: '411222',
  卢氏县: '411224',
  义马市: '411281',
  灵宝市: '411282',
  南阳市: '411300',
  宛城区: '411302',
  卧龙区: '411303',
  南召县: '411321',
  方城县: '411322',
  西峡县: '411323',
  镇平县: '411324',
  内乡县: '411325',
  淅川县: '411326',
  社旗县: '411327',
  唐河县: '411328',
  新野县: '411329',
  桐柏县: '411330',
  邓州市: '411381',
  商丘市: '411400',
  梁园区: '411402',
  睢阳区: '411403',
  民权县: '411421',
  睢县: '411422',
  宁陵县: '411423',
  柘城县: '411424',
  虞城县: '411425',
  夏邑县: '411426',
  永城市: '411481',
  信阳市: '411500',
  浉河区: '411502',
  平桥区: '411503',
  罗山县: '411521',
  光山县: '411522',
  新县: '411523',
  商城县: '411524',
  固始县: '411525',
  潢川县: '411526',
  淮滨县: '411527',
  息县: '411528',
  周口市: '411600',
  川汇区: '411602',
  扶沟县: '411621',
  西华县: '411622',
  商水县: '411623',
  沈丘县: '411624',
  郸城县: '411625',
  淮阳县: '411626',
  太康县: '411627',
  鹿邑县: '411628',
  项城市: '411681',
  驻马店市: '411700',
  驿城区: '411702',
  西平县: '411721',
  上蔡县: '411722',
  平舆县: '411723',
  正阳县: '411724',
  确山县: '411725',
  泌阳县: '411726',
  汝南县: '411727',
  遂平县: '411728',
  新蔡县: '411729',
  省直辖县级行政区划: '469000',
  济源市: '419001',
  湖北省: '420000',
  武汉市: '420100',
  江岸区: '420102',
  江汉区: '420103',
  硚口区: '420104',
  汉阳区: '420105',
  武昌区: '420106',
  洪山区: '420111',
  东西湖区: '420112',
  汉南区: '420113',
  蔡甸区: '420114',
  江夏区: '420115',
  黄陂区: '420116',
  新洲区: '420117',
  黄石市: '420200',
  黄石港区: '420202',
  西塞山区: '420203',
  下陆区: '420204',
  铁山区: '420205',
  阳新县: '420222',
  大冶市: '420281',
  十堰市: '420300',
  茅箭区: '420302',
  张湾区: '420303',
  郧县: '420321',
  郧西县: '420322',
  竹山县: '420323',
  竹溪县: '420324',
  房县: '420325',
  丹江口市: '420381',
  宜昌市: '420500',
  西陵区: '420502',
  伍家岗区: '420503',
  点军区: '420504',
  猇亭区: '420505',
  夷陵区: '420506',
  远安县: '420525',
  兴山县: '420526',
  秭归县: '420527',
  长阳土家族自治县: '420528',
  五峰土家族自治县: '420529',
  宜都市: '420581',
  当阳市: '420582',
  枝江市: '420583',
  襄阳市: '420600',
  襄城区: '420602',
  樊城区: '420606',
  襄州区: '420607',
  南漳县: '420624',
  谷城县: '420625',
  保康县: '420626',
  老河口市: '420682',
  枣阳市: '420683',
  宜城市: '420684',
  鄂州市: '420700',
  梁子湖区: '420702',
  华容区: '420703',
  鄂城区: '420704',
  荆门市: '420800',
  东宝区: '420802',
  掇刀区: '420804',
  京山县: '420821',
  沙洋县: '420822',
  钟祥市: '420881',
  孝感市: '420900',
  孝南区: '420902',
  孝昌县: '420921',
  大悟县: '420922',
  云梦县: '420923',
  应城市: '420981',
  安陆市: '420982',
  汉川市: '420984',
  荆州市: '421000',
  沙市区: '421002',
  荆州区: '421003',
  公安县: '421022',
  监利县: '421023',
  江陵县: '421024',
  石首市: '421081',
  洪湖市: '421083',
  松滋市: '421087',
  黄冈市: '421100',
  黄州区: '421102',
  团风县: '421121',
  红安县: '421122',
  罗田县: '421123',
  英山县: '421124',
  浠水县: '421125',
  蕲春县: '421126',
  黄梅县: '421127',
  麻城市: '421181',
  武穴市: '421182',
  咸宁市: '421200',
  咸安区: '421202',
  嘉鱼县: '421221',
  通城县: '421222',
  崇阳县: '421223',
  通山县: '421224',
  赤壁市: '421281',
  随州市: '421300',
  曾都区: '421303',
  随县: '421321',
  广水市: '421381',
  恩施土家族苗族自治州: '422800',
  恩施市: '422801',
  利川市: '422802',
  建始县: '422822',
  巴东县: '422823',
  宣恩县: '422825',
  咸丰县: '422826',
  来凤县: '422827',
  鹤峰县: '422828',
  仙桃市: '429004',
  潜江市: '429005',
  天门市: '429006',
  神农架林区: '429021',
  湖南省: '430000',
  长沙市: '430100',
  芙蓉区: '430102',
  天心区: '430103',
  岳麓区: '430104',
  开福区: '430105',
  雨花区: '430111',
  望城区: '430112',
  长沙县: '430121',
  宁乡县: '430124',
  浏阳市: '430181',
  株洲市: '430200',
  荷塘区: '430202',
  芦淞区: '430203',
  石峰区: '430204',
  天元区: '430211',
  株洲县: '430221',
  攸县: '430223',
  茶陵县: '430224',
  炎陵县: '430225',
  醴陵市: '430281',
  湘潭市: '430300',
  雨湖区: '430302',
  岳塘区: '430304',
  湘潭县: '430321',
  湘乡市: '430381',
  韶山市: '430382',
  衡阳市: '430400',
  珠晖区: '430405',
  雁峰区: '430406',
  石鼓区: '430407',
  蒸湘区: '430408',
  南岳区: '430412',
  衡阳县: '430421',
  衡南县: '430422',
  衡山县: '430423',
  衡东县: '430424',
  祁东县: '430426',
  耒阳市: '430481',
  常宁市: '430482',
  邵阳市: '430500',
  双清区: '430502',
  大祥区: '430503',
  北塔区: '430511',
  邵东县: '430521',
  新邵县: '430522',
  邵阳县: '430523',
  隆回县: '430524',
  洞口县: '430525',
  绥宁县: '430527',
  新宁县: '430528',
  城步苗族自治县: '430529',
  武冈市: '430581',
  岳阳市: '430600',
  岳阳楼区: '430602',
  云溪区: '430603',
  君山区: '430611',
  岳阳县: '430621',
  华容县: '430623',
  湘阴县: '430624',
  平江县: '430626',
  汨罗市: '430681',
  临湘市: '430682',
  常德市: '430700',
  武陵区: '430702',
  鼎城区: '430703',
  安乡县: '430721',
  汉寿县: '430722',
  澧县: '430723',
  临澧县: '430724',
  桃源县: '430725',
  石门县: '430726',
  津市市: '430781',
  张家界市: '430800',
  永定区: '430802',
  武陵源区: '430811',
  慈利县: '430821',
  桑植县: '430822',
  益阳市: '430900',
  资阳区: '430902',
  赫山区: '430903',
  南县: '430921',
  桃江县: '430922',
  安化县: '430923',
  沅江市: '430981',
  郴州市: '431000',
  北湖区: '431002',
  苏仙区: '431003',
  桂阳县: '431021',
  宜章县: '431022',
  永兴县: '431023',
  嘉禾县: '431024',
  临武县: '431025',
  汝城县: '431026',
  桂东县: '431027',
  安仁县: '431028',
  资兴市: '431081',
  永州市: '431100',
  零陵区: '431102',
  冷水滩区: '431103',
  祁阳县: '431121',
  东安县: '431122',
  双牌县: '431123',
  道县: '431124',
  江永县: '431125',
  宁远县: '431126',
  蓝山县: '431127',
  新田县: '431128',
  江华瑶族自治县: '431129',
  怀化市: '431200',
  鹤城区: '431202',
  中方县: '431221',
  沅陵县: '431222',
  辰溪县: '431223',
  溆浦县: '431224',
  会同县: '431225',
  麻阳苗族自治县: '431226',
  新晃侗族自治县: '431227',
  芷江侗族自治县: '431228',
  靖州苗族侗族自治县: '431229',
  通道侗族自治县: '431230',
  洪江市: '431281',
  娄底市: '431300',
  娄星区: '431302',
  双峰县: '431321',
  新化县: '431322',
  冷水江市: '431381',
  涟源市: '431382',
  湘西土家族苗族自治州: '433100',
  吉首市: '433101',
  泸溪县: '433122',
  凤凰县: '433123',
  花垣县: '433124',
  保靖县: '433125',
  古丈县: '433126',
  永顺县: '433127',
  龙山县: '433130',
  广东省: '440000',
  广州市: '440100',
  荔湾区: '440103',
  越秀区: '440104',
  海珠区: '440105',
  天河区: '440106',
  白云区: '520113',
  黄埔区: '440112',
  番禺区: '440113',
  花都区: '440114',
  南沙区: '440115',
  萝岗区: '440116',
  增城市: '440183',
  从化市: '440184',
  韶关市: '440200',
  武江区: '440203',
  浈江区: '440204',
  曲江区: '440205',
  始兴县: '440222',
  仁化县: '440224',
  翁源县: '440229',
  乳源瑶族自治县: '440232',
  新丰县: '440233',
  乐昌市: '440281',
  南雄市: '440282',
  深圳市: '440300',
  罗湖区: '440303',
  福田区: '440304',
  宝安区: '440306',
  龙岗区: '440307',
  盐田区: '440308',
  珠海市: '440400',
  香洲区: '440402',
  斗门区: '440403',
  金湾区: '440404',
  汕头市: '440500',
  龙湖区: '440507',
  金平区: '440511',
  濠江区: '440512',
  潮阳区: '440513',
  潮南区: '440514',
  澄海区: '440515',
  南澳县: '440523',
  佛山市: '440600',
  禅城区: '440604',
  南海区: '440605',
  顺德区: '440606',
  三水区: '440607',
  高明区: '440608',
  江门市: '440700',
  蓬江区: '440703',
  江海区: '440704',
  新会区: '440705',
  台山市: '440781',
  开平市: '440783',
  鹤山市: '440784',
  恩平市: '440785',
  湛江市: '440800',
  赤坎区: '440802',
  霞山区: '440803',
  坡头区: '440804',
  麻章区: '440811',
  遂溪县: '440823',
  徐闻县: '440825',
  廉江市: '440881',
  雷州市: '440882',
  吴川市: '440883',
  茂名市: '440900',
  茂南区: '440902',
  茂港区: '440903',
  电白县: '440923',
  高州市: '440981',
  化州市: '440982',
  信宜市: '440983',
  肇庆市: '441200',
  端州区: '441202',
  鼎湖区: '441203',
  广宁县: '441223',
  怀集县: '441224',
  封开县: '441225',
  德庆县: '441226',
  高要市: '441283',
  四会市: '441284',
  惠州市: '441300',
  惠城区: '441302',
  惠阳区: '441303',
  博罗县: '441322',
  惠东县: '441323',
  龙门县: '441324',
  梅州市: '441400',
  梅江区: '441402',
  梅县: '441421',
  大埔县: '441422',
  丰顺县: '441423',
  五华县: '441424',
  平远县: '441426',
  蕉岭县: '441427',
  兴宁市: '441481',
  汕尾市: '441500',
  海丰县: '441521',
  陆河县: '441523',
  陆丰市: '441581',
  河源市: '441600',
  源城区: '441602',
  紫金县: '441621',
  龙川县: '441622',
  连平县: '441623',
  和平县: '441624',
  东源县: '441625',
  阳江市: '441700',
  江城区: '441702',
  阳西县: '441721',
  阳东县: '441723',
  阳春市: '441781',
  清远市: '441800',
  清城区: '441802',
  清新区: '441803',
  佛冈县: '441821',
  阳山县: '441823',
  连山壮族瑶族自治县: '441825',
  连南瑶族自治县: '441826',
  英德市: '441881',
  连州市: '441882',
  东莞市: '441900',
  中山市: '442000',
  潮州市: '445100',
  湘桥区: '445102',
  潮安区: '445103',
  饶平县: '445122',
  揭阳市: '445200',
  榕城区: '445202',
  揭东区: '445203',
  揭西县: '445222',
  惠来县: '445224',
  普宁市: '445281',
  云浮市: '445300',
  云城区: '445302',
  新兴县: '445321',
  郁南县: '445322',
  云安县: '445323',
  罗定市: '445381',
  广西壮族自治区: '450000',
  南宁市: '450100',
  兴宁区: '450102',
  青秀区: '450103',
  江南区: '450105',
  西乡塘区: '450107',
  良庆区: '450108',
  邕宁区: '450109',
  武鸣县: '450122',
  隆安县: '450123',
  马山县: '450124',
  上林县: '450125',
  宾阳县: '450126',
  横县: '450127',
  柳州市: '450200',
  城中区: '630103',
  鱼峰区: '450203',
  柳南区: '450204',
  柳北区: '450205',
  柳江县: '450221',
  柳城县: '450222',
  鹿寨县: '450223',
  融安县: '450224',
  融水苗族自治县: '450225',
  三江侗族自治县: '450226',
  桂林市: '450300',
  秀峰区: '450302',
  叠彩区: '450303',
  象山区: '450304',
  七星区: '450305',
  雁山区: '450311',
  临桂区: '450312',
  阳朔县: '450321',
  灵川县: '450323',
  全州县: '450324',
  兴安县: '450325',
  永福县: '450326',
  灌阳县: '450327',
  龙胜各族自治县: '450328',
  资源县: '450329',
  平乐县: '450330',
  荔浦县: '450331',
  恭城瑶族自治县: '450332',
  梧州市: '450400',
  万秀区: '450403',
  长洲区: '450405',
  龙圩区: '450406',
  苍梧县: '450421',
  藤县: '450422',
  蒙山县: '450423',
  岑溪市: '450481',
  北海市: '450500',
  海城区: '450502',
  银海区: '450503',
  铁山港区: '450512',
  合浦县: '450521',
  防城港市: '450600',
  港口区: '450602',
  防城区: '450603',
  上思县: '450621',
  东兴市: '450681',
  钦州市: '450700',
  钦南区: '450702',
  钦北区: '450703',
  灵山县: '450721',
  浦北县: '450722',
  贵港市: '450800',
  港北区: '450802',
  港南区: '450803',
  覃塘区: '450804',
  平南县: '450821',
  桂平市: '450881',
  玉林市: '450900',
  玉州区: '450902',
  福绵区: '450903',
  容县: '450921',
  陆川县: '450922',
  博白县: '450923',
  兴业县: '450924',
  北流市: '450981',
  百色市: '451000',
  右江区: '451002',
  田阳县: '451021',
  田东县: '451022',
  平果县: '451023',
  德保县: '451024',
  靖西县: '451025',
  那坡县: '451026',
  凌云县: '451027',
  乐业县: '451028',
  田林县: '451029',
  西林县: '451030',
  隆林各族自治县: '451031',
  贺州市: '451100',
  八步区: '451102',
  昭平县: '451121',
  钟山县: '451122',
  富川瑶族自治县: '451123',
  河池市: '451200',
  金城江区: '451202',
  南丹县: '451221',
  天峨县: '451222',
  凤山县: '451223',
  东兰县: '451224',
  罗城仫佬族自治县: '451225',
  环江毛南族自治县: '451226',
  巴马瑶族自治县: '451227',
  都安瑶族自治县: '451228',
  大化瑶族自治县: '451229',
  宜州市: '451281',
  来宾市: '451300',
  兴宾区: '451302',
  忻城县: '451321',
  象州县: '451322',
  武宣县: '451323',
  金秀瑶族自治县: '451324',
  合山市: '451381',
  崇左市: '451400',
  江州区: '451402',
  扶绥县: '451421',
  宁明县: '451422',
  龙州县: '451423',
  大新县: '451424',
  天等县: '451425',
  凭祥市: '451481',
  海南省: '460000',
  海口市: '460100',
  秀英区: '460105',
  龙华区: '460106',
  琼山区: '460107',
  美兰区: '460108',
  三亚市: '460200',
  三沙市: '460300',
  西沙群岛: '460321',
  南沙群岛: '460322',
  中沙群岛的岛礁及其海域: '460323',
  五指山市: '469001',
  琼海市: '469002',
  儋州市: '469003',
  文昌市: '469005',
  万宁市: '469006',
  东方市: '469007',
  定安县: '469021',
  屯昌县: '469022',
  澄迈县: '469023',
  临高县: '469024',
  白沙黎族自治县: '469025',
  昌江黎族自治县: '469026',
  乐东黎族自治县: '469027',
  陵水黎族自治县: '469028',
  保亭黎族苗族自治县: '469029',
  琼中黎族苗族自治县: '469030',
  重庆市: '500100',
  万州区: '500101',
  涪陵区: '500102',
  渝中区: '500103',
  大渡口区: '500104',
  沙坪坝区: '500106',
  九龙坡区: '500107',
  南岸区: '500108',
  北碚区: '500109',
  綦江区: '500110',
  大足区: '500111',
  渝北区: '500112',
  巴南区: '500113',
  黔江区: '500114',
  长寿区: '500115',
  江津区: '500116',
  合川区: '500117',
  永川区: '500118',
  南川区: '500119',
  潼南县: '500223',
  铜梁县: '500224',
  荣昌县: '500226',
  璧山县: '500227',
  梁平县: '500228',
  城口县: '500229',
  丰都县: '500230',
  垫江县: '500231',
  武隆县: '500232',
  忠县: '500233',
  开县: '500234',
  云阳县: '500235',
  奉节县: '500236',
  巫山县: '500237',
  巫溪县: '500238',
  石柱土家族自治县: '500240',
  秀山土家族苗族自治县: '500241',
  酉阳土家族苗族自治县: '500242',
  彭水苗族土家族自治县: '500243',
  四川省: '510000',
  成都市: '510100',
  锦江区: '510104',
  青羊区: '510105',
  金牛区: '510106',
  武侯区: '510107',
  成华区: '510108',
  龙泉驿区: '510112',
  青白江区: '510113',
  新都区: '510114',
  温江区: '510115',
  金堂县: '510121',
  郫都区: '510117',
  大邑县: '510129',
  蒲江县: '510131',
  双流区: '510116',
  新津区: '510118',
  都江堰市: '510181',
  彭州市: '510182',
  邛崃市: '510183',
  崇州市: '510184',
  自贡市: '510300',
  自流井区: '510302',
  贡井区: '510303',
  大安区: '510304',
  沿滩区: '510311',
  荣县: '510321',
  富顺县: '510322',
  攀枝花市: '510400',
  东区: '510402',
  西区: '510403',
  仁和区: '510411',
  米易县: '510421',
  盐边县: '510422',
  泸州市: '510500',
  江阳区: '510502',
  纳溪区: '510503',
  龙马潭区: '510504',
  泸县: '510521',
  合江县: '510522',
  叙永县: '510524',
  古蔺县: '510525',
  德阳市: '510600',
  旌阳区: '510603',
  中江县: '510623',
  罗江县: '510626',
  广汉市: '510681',
  什邡市: '510682',
  绵竹市: '510683',
  绵阳市: '510700',
  涪城区: '510703',
  游仙区: '510704',
  三台县: '510722',
  盐亭县: '510723',
  安县: '510724',
  梓潼县: '510725',
  北川羌族自治县: '510726',
  平武县: '510727',
  江油市: '510781',
  广元市: '510800',
  利州区: '510802',
  元坝区: '510811',
  朝天区: '510812',
  旺苍县: '510821',
  青川县: '510822',
  剑阁县: '510823',
  苍溪县: '510824',
  遂宁市: '510900',
  船山区: '510903',
  安居区: '510904',
  蓬溪县: '510921',
  射洪县: '510922',
  大英县: '510923',
  内江市: '511000',
  东兴区: '511011',
  威远县: '511024',
  资中县: '511025',
  隆昌县: '511028',
  乐山市: '511100',
  沙湾区: '511111',
  五通桥区: '511112',
  金口河区: '511113',
  犍为县: '511123',
  井研县: '511124',
  夹江县: '511126',
  沐川县: '511129',
  峨边彝族自治县: '511132',
  马边彝族自治县: '511133',
  峨眉山市: '511181',
  南充市: '511300',
  顺庆区: '511302',
  高坪区: '511303',
  嘉陵区: '511304',
  南部县: '511321',
  营山县: '511322',
  蓬安县: '511323',
  仪陇县: '511324',
  西充县: '511325',
  阆中市: '511381',
  眉山市: '511400',
  东坡区: '511402',
  仁寿县: '511421',
  彭山区: '511403',
  洪雅县: '511423',
  丹棱县: '511424',
  青神县: '511425',
  宜宾市: '511500',
  翠屏区: '511502',
  南溪区: '511503',
  宜宾县: '511521',
  江安县: '511523',
  长宁县: '511524',
  高县: '511525',
  珙县: '511526',
  筠连县: '511527',
  兴文县: '511528',
  屏山县: '511529',
  广安市: '511600',
  广安区: '511602',
  前锋区: '511603',
  岳池县: '511621',
  武胜县: '511622',
  邻水县: '511623',
  华蓥市: '511681',
  达州市: '511700',
  通川区: '511702',
  达川区: '511703',
  宣汉县: '511722',
  开江县: '511723',
  大竹县: '511724',
  渠县: '511725',
  万源市: '511781',
  雅安市: '511800',
  雨城区: '511802',
  名山区: '511803',
  荥经县: '511822',
  汉源县: '511823',
  石棉县: '511824',
  天全县: '511825',
  芦山县: '511826',
  宝兴县: '511827',
  巴中市: '511900',
  巴州区: '511902',
  恩阳区: '511903',
  通江县: '511921',
  南江县: '511922',
  平昌县: '511923',
  资阳市: '512000',
  雁江区: '512002',
  安岳县: '512021',
  乐至县: '512022',
  简阳市: '510185',
  阿坝藏族羌族自治州: '513200',
  汶川县: '513221',
  理县: '513222',
  茂县: '513223',
  松潘县: '513224',
  九寨沟县: '513225',
  金川县: '513226',
  小金县: '513227',
  黑水县: '513228',
  马尔康县: '513229',
  壤塘县: '513230',
  阿坝县: '513231',
  若尔盖县: '513232',
  红原县: '513233',
  甘孜藏族自治州: '513300',
  康定县: '513321',
  泸定县: '513322',
  丹巴县: '513323',
  九龙县: '513324',
  雅江县: '513325',
  道孚县: '513326',
  炉霍县: '513327',
  甘孜县: '513328',
  新龙县: '513329',
  德格县: '513330',
  白玉县: '513331',
  石渠县: '513332',
  色达县: '513333',
  理塘县: '513334',
  巴塘县: '513335',
  乡城县: '513336',
  稻城县: '513337',
  得荣县: '513338',
  凉山彝族自治州: '513400',
  西昌市: '513401',
  木里藏族自治县: '513422',
  盐源县: '513423',
  德昌县: '513424',
  会理县: '513425',
  会东县: '513426',
  宁南县: '513427',
  普格县: '513428',
  布拖县: '513429',
  金阳县: '513430',
  昭觉县: '513431',
  喜德县: '513432',
  冕宁县: '513433',
  越西县: '513434',
  甘洛县: '513435',
  美姑县: '513436',
  雷波县: '513437',
  贵州省: '520000',
  贵阳市: '520100',
  南明区: '520102',
  云岩区: '520103',
  花溪区: '520111',
  乌当区: '520112',
  观山湖区: '520115',
  开阳县: '520121',
  息烽县: '520122',
  修文县: '520123',
  清镇市: '520181',
  六盘水市: '520200',
  钟山区: '520201',
  六枝特区: '520203',
  水城县: '520221',
  盘县: '520222',
  遵义市: '520300',
  红花岗区: '520302',
  汇川区: '520303',
  遵义县: '520321',
  桐梓县: '520322',
  绥阳县: '520323',
  正安县: '520324',
  道真仡佬族苗族自治县: '520325',
  务川仡佬族苗族自治县: '520326',
  凤冈县: '520327',
  湄潭县: '520328',
  余庆县: '520329',
  习水县: '520330',
  赤水市: '520381',
  仁怀市: '520382',
  安顺市: '520400',
  西秀区: '520402',
  平坝县: '520421',
  普定县: '520422',
  镇宁布依族苗族自治县: '520423',
  关岭布依族苗族自治县: '520424',
  紫云苗族布依族自治县: '520425',
  毕节市: '520500',
  七星关区: '520502',
  大方县: '520521',
  黔西县: '520522',
  金沙县: '520523',
  织金县: '520524',
  纳雍县: '520525',
  威宁彝族回族苗族自治县: '520526',
  赫章县: '520527',
  铜仁市: '520600',
  碧江区: '520602',
  万山区: '520603',
  江口县: '520621',
  玉屏侗族自治县: '520622',
  石阡县: '520623',
  思南县: '520624',
  印江土家族苗族自治县: '520625',
  德江县: '520626',
  沿河土家族自治县: '520627',
  松桃苗族自治县: '520628',
  黔西南布依族苗族自治州: '522300',
  兴义市: '522301',
  兴仁县: '522322',
  普安县: '522323',
  晴隆县: '522324',
  贞丰县: '522325',
  望谟县: '522326',
  册亨县: '522327',
  安龙县: '522328',
  黔东南苗族侗族自治州: '522600',
  凯里市: '522601',
  黄平县: '522622',
  施秉县: '522623',
  三穗县: '522624',
  镇远县: '522625',
  岑巩县: '522626',
  天柱县: '522627',
  锦屏县: '522628',
  剑河县: '522629',
  台江县: '522630',
  黎平县: '522631',
  榕江县: '522632',
  从江县: '522633',
  雷山县: '522634',
  麻江县: '522635',
  丹寨县: '522636',
  黔南布依族苗族自治州: '522700',
  都匀市: '522701',
  福泉市: '522702',
  荔波县: '522722',
  贵定县: '522723',
  瓮安县: '522725',
  独山县: '522726',
  平塘县: '522727',
  罗甸县: '522728',
  长顺县: '522729',
  龙里县: '522730',
  惠水县: '522731',
  三都水族自治县: '522732',
  云南省: '530000',
  昆明市: '530100',
  五华区: '530102',
  盘龙区: '530103',
  官渡区: '530111',
  西山区: '530112',
  东川区: '530113',
  呈贡区: '530114',
  晋宁县: '530122',
  富民县: '530124',
  宜良县: '530125',
  石林彝族自治县: '530126',
  嵩明县: '530127',
  禄劝彝族苗族自治县: '530128',
  寻甸回族彝族自治县: '530129',
  安宁市: '530181',
  曲靖市: '530300',
  麒麟区: '530302',
  马龙县: '530321',
  陆良县: '530322',
  师宗县: '530323',
  罗平县: '530324',
  富源县: '530325',
  会泽县: '530326',
  沾益县: '530328',
  宣威市: '530381',
  玉溪市: '530400',
  红塔区: '530402',
  江川县: '530421',
  澄江县: '530422',
  通海县: '530423',
  华宁县: '530424',
  易门县: '530425',
  峨山彝族自治县: '530426',
  新平彝族傣族自治县: '530427',
  元江哈尼族彝族傣族自治县: '530428',
  保山市: '530500',
  隆阳区: '530502',
  施甸县: '530521',
  腾冲县: '530522',
  龙陵县: '530523',
  昌宁县: '530524',
  昭通市: '530600',
  昭阳区: '530602',
  鲁甸县: '530621',
  巧家县: '530622',
  盐津县: '530623',
  大关县: '530624',
  永善县: '530625',
  绥江县: '530626',
  镇雄县: '530627',
  彝良县: '530628',
  威信县: '530629',
  水富县: '530630',
  丽江市: '530700',
  古城区: '530702',
  玉龙纳西族自治县: '530721',
  永胜县: '530722',
  华坪县: '530723',
  宁蒗彝族自治县: '530724',
  普洱市: '530800',
  思茅区: '530802',
  宁洱哈尼族彝族自治县: '530821',
  墨江哈尼族自治县: '530822',
  景东彝族自治县: '530823',
  景谷傣族彝族自治县: '530824',
  镇沅彝族哈尼族拉祜族自治县: '530825',
  江城哈尼族彝族自治县: '530826',
  孟连傣族拉祜族佤族自治县: '530827',
  澜沧拉祜族自治县: '530828',
  西盟佤族自治县: '530829',
  临沧市: '530900',
  临翔区: '530902',
  凤庆县: '530921',
  云县: '530922',
  永德县: '530923',
  镇康县: '530924',
  双江拉祜族佤族布朗族傣族自治县: '530925',
  耿马傣族佤族自治县: '530926',
  沧源佤族自治县: '530927',
  楚雄彝族自治州: '532300',
  楚雄市: '532301',
  双柏县: '532322',
  牟定县: '532323',
  南华县: '532324',
  姚安县: '532325',
  大姚县: '532326',
  永仁县: '532327',
  元谋县: '532328',
  武定县: '532329',
  禄丰县: '532331',
  红河哈尼族彝族自治州: '532500',
  个旧市: '532501',
  开远市: '532502',
  蒙自市: '532503',
  弥勒市: '532504',
  屏边苗族自治县: '532523',
  建水县: '532524',
  石屏县: '532525',
  泸西县: '532527',
  元阳县: '532528',
  红河县: '532529',
  金平苗族瑶族傣族自治县: '532530',
  绿春县: '532531',
  河口瑶族自治县: '532532',
  文山壮族苗族自治州: '532600',
  文山市: '532601',
  砚山县: '532622',
  西畴县: '532623',
  麻栗坡县: '532624',
  马关县: '532625',
  丘北县: '532626',
  广南县: '532627',
  富宁县: '532628',
  西双版纳傣族自治州: '532800',
  景洪市: '532801',
  勐海县: '532822',
  勐腊县: '532823',
  大理白族自治州: '532900',
  大理市: '532901',
  漾濞彝族自治县: '532922',
  祥云县: '532923',
  宾川县: '532924',
  弥渡县: '532925',
  南涧彝族自治县: '532926',
  巍山彝族回族自治县: '532927',
  永平县: '532928',
  云龙县: '532929',
  洱源县: '532930',
  剑川县: '532931',
  鹤庆县: '532932',
  德宏傣族景颇族自治州: '533100',
  瑞丽市: '533102',
  芒市: '533103',
  梁河县: '533122',
  盈江县: '533123',
  陇川县: '533124',
  怒江傈僳族自治州: '533300',
  泸水县: '533321',
  福贡县: '533323',
  贡山独龙族怒族自治县: '533324',
  兰坪白族普米族自治县: '533325',
  迪庆藏族自治州: '533400',
  香格里拉县: '533421',
  德钦县: '533422',
  维西傈僳族自治县: '533423',
  西藏自治区: '540000',
  拉萨市: '540100',
  城关区: '620102',
  林周县: '540121',
  当雄县: '540122',
  尼木县: '540123',
  曲水县: '540124',
  堆龙德庆县: '540125',
  达孜县: '540126',
  墨竹工卡县: '540127',
  昌都地区: '542100',
  昌都县: '542121',
  江达县: '542122',
  贡觉县: '542123',
  类乌齐县: '542124',
  丁青县: '542125',
  察雅县: '542126',
  八宿县: '542127',
  左贡县: '542128',
  芒康县: '542129',
  洛隆县: '542132',
  边坝县: '542133',
  山南地区: '542200',
  乃东县: '542221',
  扎囊县: '542222',
  贡嘎县: '542223',
  桑日县: '542224',
  琼结县: '542225',
  曲松县: '542226',
  措美县: '542227',
  洛扎县: '542228',
  加查县: '542229',
  隆子县: '542231',
  错那县: '542232',
  浪卡子县: '542233',
  日喀则地区: '542300',
  日喀则市: '542301',
  南木林县: '542322',
  江孜县: '542323',
  定日县: '542324',
  萨迦县: '542325',
  拉孜县: '542326',
  昂仁县: '542327',
  谢通门县: '542328',
  白朗县: '542329',
  仁布县: '542330',
  康马县: '542331',
  定结县: '542332',
  仲巴县: '542333',
  亚东县: '542334',
  吉隆县: '542335',
  聂拉木县: '542336',
  萨嘎县: '542337',
  岗巴县: '542338',
  那曲地区: '542400',
  那曲县: '542421',
  嘉黎县: '542422',
  比如县: '542423',
  聂荣县: '542424',
  安多县: '542425',
  申扎县: '542426',
  索县: '542427',
  班戈县: '542428',
  巴青县: '542429',
  尼玛县: '542430',
  双湖县: '542431',
  阿里地区: '542500',
  普兰县: '542521',
  札达县: '542522',
  噶尔县: '542523',
  日土县: '542524',
  革吉县: '542525',
  改则县: '542526',
  措勤县: '542527',
  林芝地区: '542600',
  林芝县: '542621',
  工布江达县: '542622',
  米林县: '542623',
  墨脱县: '542624',
  波密县: '542625',
  察隅县: '542626',
  朗县: '542627',
  陕西省: '610000',
  西安市: '610100',
  碑林区: '610103',
  莲湖区: '610104',
  灞桥区: '610111',
  未央区: '610112',
  雁塔区: '610113',
  阎良区: '610114',
  临潼区: '610115',
  蓝田县: '610122',
  周至县: '610124',
  户县: '610125',
  高陵县: '610126',
  铜川市: '610200',
  王益区: '610202',
  印台区: '610203',
  耀州区: '610204',
  宜君县: '610222',
  宝鸡市: '610300',
  渭滨区: '610302',
  金台区: '610303',
  陈仓区: '610304',
  凤翔县: '610322',
  岐山县: '610323',
  扶风县: '610324',
  眉县: '610326',
  陇县: '610327',
  千阳县: '610328',
  麟游县: '610329',
  凤县: '610330',
  太白县: '610331',
  咸阳市: '610400',
  秦都区: '610402',
  杨陵区: '610403',
  渭城区: '610404',
  三原县: '610422',
  泾阳县: '610423',
  乾县: '610424',
  礼泉县: '610425',
  永寿县: '610426',
  彬县: '610427',
  长武县: '610428',
  旬邑县: '610429',
  淳化县: '610430',
  武功县: '610431',
  兴平市: '610481',
  渭南市: '610500',
  临渭区: '610502',
  华县: '610521',
  潼关县: '610522',
  大荔县: '610523',
  合阳县: '610524',
  澄城县: '610525',
  蒲城县: '610526',
  白水县: '610527',
  富平县: '610528',
  韩城市: '610581',
  华阴市: '610582',
  延安市: '610600',
  宝塔区: '610602',
  延长县: '610621',
  延川县: '610622',
  子长县: '610623',
  安塞县: '610624',
  志丹县: '610625',
  吴起县: '610626',
  甘泉县: '610627',
  富县: '610628',
  洛川县: '610629',
  宜川县: '610630',
  黄龙县: '610631',
  黄陵县: '610632',
  汉中市: '610700',
  汉台区: '610702',
  南郑县: '610721',
  城固县: '610722',
  洋县: '610723',
  西乡县: '610724',
  勉县: '610725',
  宁强县: '610726',
  略阳县: '610727',
  镇巴县: '610728',
  留坝县: '610729',
  佛坪县: '610730',
  榆林市: '610800',
  榆阳区: '610802',
  神木县: '610821',
  府谷县: '610822',
  横山县: '610823',
  靖边县: '610824',
  定边县: '610825',
  绥德县: '610826',
  米脂县: '610827',
  佳县: '610828',
  吴堡县: '610829',
  清涧县: '610830',
  子洲县: '610831',
  安康市: '610900',
  汉滨区: '610902',
  汉阴县: '610921',
  石泉县: '610922',
  宁陕县: '610923',
  紫阳县: '610924',
  岚皋县: '610925',
  平利县: '610926',
  镇坪县: '610927',
  旬阳县: '610928',
  白河县: '610929',
  商洛市: '611000',
  商州区: '611002',
  洛南县: '611021',
  丹凤县: '611022',
  商南县: '611023',
  山阳县: '611024',
  镇安县: '611025',
  柞水县: '611026',
  甘肃省: '620000',
  兰州市: '620100',
  七里河区: '620103',
  西固区: '620104',
  安宁区: '620105',
  红古区: '620111',
  永登县: '620121',
  皋兰县: '620122',
  榆中县: '620123',
  嘉峪关市: '620200',
  金昌市: '620300',
  金川区: '620302',
  永昌县: '620321',
  白银市: '620400',
  白银区: '620402',
  平川区: '620403',
  靖远县: '620421',
  会宁县: '620422',
  景泰县: '620423',
  天水市: '620500',
  秦州区: '620502',
  麦积区: '620503',
  清水县: '620521',
  秦安县: '620522',
  甘谷县: '620523',
  武山县: '620524',
  张家川回族自治县: '620525',
  武威市: '620600',
  凉州区: '620602',
  民勤县: '620621',
  古浪县: '620622',
  天祝藏族自治县: '620623',
  张掖市: '620700',
  甘州区: '620702',
  肃南裕固族自治县: '620721',
  民乐县: '620722',
  临泽县: '620723',
  高台县: '620724',
  山丹县: '620725',
  平凉市: '620800',
  崆峒区: '620802',
  泾川县: '620821',
  灵台县: '620822',
  崇信县: '620823',
  华亭县: '620824',
  庄浪县: '620825',
  静宁县: '620826',
  酒泉市: '620900',
  肃州区: '620902',
  金塔县: '620921',
  瓜州县: '620922',
  肃北蒙古族自治县: '620923',
  阿克塞哈萨克族自治县: '620924',
  玉门市: '620981',
  敦煌市: '620982',
  庆阳市: '621000',
  西峰区: '621002',
  庆城县: '621021',
  环县: '621022',
  华池县: '621023',
  合水县: '621024',
  正宁县: '621025',
  宁县: '621026',
  镇原县: '621027',
  定西市: '621100',
  安定区: '621102',
  通渭县: '621121',
  陇西县: '621122',
  渭源县: '621123',
  临洮县: '621124',
  漳县: '621125',
  岷县: '621126',
  陇南市: '621200',
  武都区: '621202',
  成县: '621221',
  文县: '621222',
  宕昌县: '621223',
  康县: '621224',
  西和县: '621225',
  礼县: '621226',
  徽县: '621227',
  两当县: '621228',
  临夏回族自治州: '622900',
  临夏市: '622901',
  临夏县: '622921',
  康乐县: '622922',
  永靖县: '622923',
  广河县: '622924',
  和政县: '622925',
  东乡族自治县: '622926',
  积石山保安族东乡族撒拉族自治县: '622927',
  甘南藏族自治州: '623000',
  合作市: '623001',
  临潭县: '623021',
  卓尼县: '623022',
  舟曲县: '623023',
  迭部县: '623024',
  玛曲县: '623025',
  碌曲县: '623026',
  夏河县: '623027',
  青海省: '630000',
  西宁市: '630100',
  城东区: '630102',
  城西区: '630104',
  城北区: '630105',
  大通回族土族自治县: '630121',
  湟中县: '630122',
  湟源县: '630123',
  海东市: '630200',
  乐都区: '630202',
  平安县: '630221',
  民和回族土族自治县: '630222',
  互助土族自治县: '630223',
  化隆回族自治县: '630224',
  循化撒拉族自治县: '630225',
  海北藏族自治州: '632200',
  门源回族自治县: '632221',
  祁连县: '632222',
  海晏县: '632223',
  刚察县: '632224',
  黄南藏族自治州: '632300',
  同仁县: '632321',
  尖扎县: '632322',
  泽库县: '632323',
  河南蒙古族自治县: '632324',
  海南藏族自治州: '632500',
  共和县: '632521',
  同德县: '632522',
  贵德县: '632523',
  兴海县: '632524',
  贵南县: '632525',
  果洛藏族自治州: '632600',
  玛沁县: '632621',
  班玛县: '632622',
  甘德县: '632623',
  达日县: '632624',
  久治县: '632625',
  玛多县: '632626',
  玉树藏族自治州: '632700',
  玉树市: '632701',
  杂多县: '632722',
  称多县: '632723',
  治多县: '632724',
  囊谦县: '632725',
  曲麻莱县: '632726',
  海西蒙古族藏族自治州: '632800',
  格尔木市: '632801',
  德令哈市: '632802',
  乌兰县: '632821',
  都兰县: '632822',
  天峻县: '632823',
  宁夏回族自治区: '640000',
  银川市: '640100',
  兴庆区: '640104',
  西夏区: '640105',
  金凤区: '640106',
  永宁县: '640121',
  贺兰县: '640122',
  灵武市: '640181',
  石嘴山市: '640200',
  大武口区: '640202',
  惠农区: '640205',
  平罗县: '640221',
  吴忠市: '640300',
  利通区: '640302',
  红寺堡区: '640303',
  盐池县: '640323',
  同心县: '640324',
  青铜峡市: '640381',
  固原市: '640400',
  原州区: '640402',
  西吉县: '640422',
  隆德县: '640423',
  泾源县: '640424',
  彭阳县: '640425',
  中卫市: '640500',
  沙坡头区: '640502',
  中宁县: '640521',
  海原县: '640522',
  新疆维吾尔自治区: '650000',
  乌鲁木齐市: '650100',
  天山区: '650102',
  沙依巴克区: '650103',
  水磨沟区: '650105',
  头屯河区: '650106',
  达坂城区: '650107',
  米东区: '650109',
  乌鲁木齐县: '650121',
  克拉玛依市: '650200',
  独山子区: '650202',
  克拉玛依区: '650203',
  白碱滩区: '650204',
  乌尔禾区: '650205',
  吐鲁番地区: '652100',
  吐鲁番市: '652101',
  鄯善县: '652122',
  托克逊县: '652123',
  哈密地区: '652200',
  哈密市: '652201',
  巴里坤哈萨克自治县: '652222',
  伊吾县: '652223',
  昌吉回族自治州: '652300',
  昌吉市: '652301',
  阜康市: '652302',
  呼图壁县: '652323',
  玛纳斯县: '652324',
  奇台县: '652325',
  吉木萨尔县: '652327',
  木垒哈萨克自治县: '652328',
  博尔塔拉蒙古自治州: '652700',
  博乐市: '652701',
  阿拉山口市: '652702',
  精河县: '652722',
  温泉县: '652723',
  巴音郭楞蒙古自治州: '652800',
  库尔勒市: '652801',
  轮台县: '652822',
  尉犁县: '652823',
  若羌县: '652824',
  且末县: '652825',
  焉耆回族自治县: '652826',
  和静县: '652827',
  和硕县: '652828',
  博湖县: '652829',
  阿克苏地区: '652900',
  阿克苏市: '652901',
  温宿县: '652922',
  库车县: '652923',
  沙雅县: '652924',
  新和县: '652925',
  拜城县: '652926',
  乌什县: '652927',
  阿瓦提县: '652928',
  柯坪县: '652929',
  克孜勒苏柯尔克孜自治州: '653000',
  阿图什市: '653001',
  阿克陶县: '653022',
  阿合奇县: '653023',
  乌恰县: '653024',
  喀什地区: '653100',
  喀什市: '653101',
  疏附县: '653121',
  疏勒县: '653122',
  英吉沙县: '653123',
  泽普县: '653124',
  莎车县: '653125',
  叶城县: '653126',
  麦盖提县: '653127',
  岳普湖县: '653128',
  伽师县: '653129',
  巴楚县: '653130',
  塔什库尔干塔吉克自治县: '653131',
  和田地区: '653200',
  和田市: '653201',
  和田县: '653221',
  墨玉县: '653222',
  皮山县: '653223',
  洛浦县: '653224',
  策勒县: '653225',
  于田县: '653226',
  民丰县: '653227',
  伊犁哈萨克自治州: '654000',
  伊宁市: '654002',
  奎屯市: '654003',
  伊宁县: '654021',
  察布查尔锡伯自治县: '654022',
  霍城县: '654023',
  巩留县: '654024',
  新源县: '654025',
  昭苏县: '654026',
  特克斯县: '654027',
  尼勒克县: '654028',
  塔城地区: '654200',
  塔城市: '654201',
  乌苏市: '654202',
  额敏县: '654221',
  沙湾县: '654223',
  托里县: '654224',
  裕民县: '654225',
  和布克赛尔蒙古自治县: '654226',
  阿勒泰地区: '654300',
  阿勒泰市: '654301',
  布尔津县: '654321',
  富蕴县: '654322',
  福海县: '654323',
  哈巴河县: '654324',
  青河县: '654325',
  吉木乃县: '654326',
  自治区直辖县级行政区划: '659000',
  石河子市: '659001',
  阿拉尔市: '659002',
  图木舒克市: '659003',
  五家渠市: '659004',
  台湾省: '710000',
  香港特别行政区: '810000',
  澳门特别行政区: '820000',
}

export default cityMap
