import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './utils/filter'
// import BaiduMap from 'vue-baidu-map'
Vue.config.productionTip = false
//注册过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
// 引入elementui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 引入滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import moment from 'moment'
// 引入中间地图的js
// import './assets/china'
import 'echarts-wordcloud'
import '@/permission'
Vue.prototype.moment = moment
// Vue.use(BaiduMap, {
//   ak:'03iihTwAtSBfySW86G36ByHHqIzU7dfd',
// })
new Vue({
  // 注册路由
  router,
  // 注册vuex
  store,
  render: (h) => h(App),
}).$mount('#app')
