<template>
  <!-- 一级页面右侧模块 -->
  <div class="serviceCenter">
    <!-- 综合体养老服务中心 -->
    <serviceCenter :levelAndCode="levelAndCode"></serviceCenter>
    <!-- 老年用品市场年度销售 -->
    <annualSales :levelAndCode="levelAndCode"></annualSales>
    <!-- 公共卫生 -->
    <publicHealth></publicHealth>
    <!-- 旅游旅居 -->
    <sojourn></sojourn>
  </div>
</template>

<script>
// 综合体养老服务中心
import serviceCenter from './components/serviceCenter.vue'
// 老年用品市场年度销售
import annualSales from './components/annualSales.vue'
// 公共卫生
import publicHealth from './components/publicHealth.vue'
// 旅游旅居
import sojourn from './components/sojourn.vue'
export default {
  components: {
    serviceCenter,
    annualSales,
    publicHealth,
    sojourn,
  },
  data() {
    return {}
  },
  props: {
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>
.serviceCenter {
  flex: 3.05;
  padding-top: 0.56rem;
  box-sizing: border-box;
}
</style>
