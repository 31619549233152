<template>
  <!-- 公共卫生 -->
  <div>
    <div v-for="(item, index) in dataList" :key="index" class="hygiene-com">
      <div class="hygiene">
        <div class="h-echarts">
          <div class="ec-name">
            <div class="name-c">{{ item.name }}</div>
            <div class="pb">
              <div
                class="progress-bar"
                :style="{ width: item.hygieneWidth + 'rem', height: '10px' }"
              ></div>
              <div class="name-ren">
                {{ item.num + '人' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 数据
      dataList: [
        {
          id: 1,
          name: '公共卫生',
          num: 1358,
          hygieneWidth: 0,
        },
        {
          id: 2,
          name: '残疾人服务',
          num: 1585,
          hygieneWidth: 0,
        },
        {
          id: 3,
          name: '青少年服务',
          num: 642,
          hygieneWidth: 0,
        },
      ],
    }
  },
  mounted() {
    // 公共卫生
    this.forHygieneCharts()
  },
  methods: {
    forHygieneCharts() {
      let nums = 0
      this.dataList.forEach((item) => {
        nums += item.num
      })
      this.dataList.forEach((item) => {
        let numDatas = item.num / nums
        if (numDatas > 0.5) {
          item.hygieneWidth = numDatas * 12
        } else {
          item.hygieneWidth = numDatas * 24
        }
      })
    },
  },
}
</script>
<style scoped>
.ec-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7fb5f6;
  width: 100%;
}
.pb {
  flex: 1;
  display: flex;
}
.name-c {
  width: 3.5rem;
  text-align: left;
  margin-right: 0.3rem;
  font-size: 0.7rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #7fb5f6;
}

.progress-bar {
  background: linear-gradient(90deg, #28d8ee, #096eff);
  border: 1px solid #02246c;

  border-radius: 5px;
  text-align: left;
  margin-right: 0.5rem;
}
.name-ren {
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #7fb5f6;
  font-size: 0.5rem;
}
.hygiene-com {
  position: relative;
  background: url(../../../../image/top/borderback1.png) 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
}
.hygiene {
  padding: 0.5rem;
}
.h-echarts {
  display: flex;
  align-items: center;
  height: 0.9rem;
  width: 100%;
}
</style>
