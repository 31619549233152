<template>
  <!-- 中间部分 -->
  <div class="column">
    <!-- 右上角 -->
    <div class="ds-border">
      <div class="contenty">
        <span class="govement">服务老人数</span>
        <div class="select">
          <ScrollNumber :val="formatNumber(numdekr, 2, 1)"></ScrollNumber>
        </div>
      </div>
    </div>
    <br />
    <!-- 颐伦养老服务机构全国分布图 -->
    <div class="map">
      <div class="navigation">
        <span @click="handleToChina"> 中国 </span>
        <span @click="handleToProvince" v-if="provinceName">
          >{{ provinceName }}</span
        ><span @click="handleToCity" v-if="cityName"> >{{ cityName }} </span>
      </div>
      <div class="map-title">
        <img src="../../image/top/fengbu.png" alt="" />
      </div>
      <div class="chart">
        <div class="geo">
          <div class="total"></div>
          <div class="map_box">
            <div
              id="mapChart"
              class="chart"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
        <!-- <a href="javascript:void(0);" id="gobtn" class="hide" @click="goback"
          >返回上级</a
        > -->
      </div>
    </div>
    <!-- 养老人才培训，服务人员概况 -->
    <div class="users">
      <div class="inner">
        <div class="chart">
          <div class="top-title"></div>
          <div class="managers">
            <div class="numder-res">
              <span class="ds-tit">246</span>
              <span class="ds-num">管理者人数</span>
            </div>
            <div class="numder-res">
              <span class="ds-tit">960</span>
              <span class="ds-num">护理人员人数</span>
            </div>
            <div class="volunteers">
              <span class="ds-tit">97</span>
              <span class="ds-num">志愿者（含社工）人数</span>
            </div>
          </div>
          <div class="managers">
            <div class="numder-res1">
              <span class="ds-tit1">336</span>
              <span class="ds-num1">管理者人次</span>
            </div>
            <div class="numder-res1">
              <span class="ds-tit1">2850</span>
              <span class="ds-num1">护理人员人次</span>
            </div>
            <div class="volunteers1">
              <span class="ds-tit1">309</span>
              <span class="ds-num1">志愿者（含社工）人次</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inner">
        <div class="hotwords">
          <div class="top-title"></div>
          <div class="servicehotwords">
            <div id="graphics" style="width: 100%; height: 100%"></div>
            <div class="fwryzs">服务人员总数</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入echarts
import cityMap from '@/js/china-main-city-map.js'
let echarts = require('echarts')
// import myMixin from "../../js/mixin.js";

//中国地图（第一级地图）的ID、Name、Json数据
var chinaId = 100000
var chinaName = 'china'
var chinaJson = null

//记录父级ID、Name
var mapStack = []
var parentId = null
var parentName = null
var areaData = []
//Echarts地图全局变量，主要是在返回上级地图的方法中会用到
var myChart = null

// 引入滚动插件
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getScreenArea, perSonnel, customers } from '@/api/allData'
import ScrollNumber from './digitalRollover.vue'
// 颐伦养老服务机构全国分布图数据

export default {
  // 注册组件
  components: {
    // 滚动插件
    vueSeamlessScroll,
    // 数字滚动
    ScrollNumber,
  },
  // 定义数据
  data() {
    return {
      numdekr: 123456,
      careWorker: '',
      // 颐伦会员服务热词
      flag: false,
      classOption: {
        direction: 0,
      },
      mapList: [],
      //   养老人才培训
      dataList: [
        { name: '管理者人数', tit: 1000 },
        { name: '护理人员人数', tit: 200 },
        { name: '志愿者（含社工）人数', tit: 100 },
      ],
      dataPage: [
        { name: '管理者人次', tit: 3000 },
        { name: '护理人员人次', tit: 300 },
        { name: '志愿者（含社工）人次', tit: 300 },
      ],
      provinceName: null,
      cityName: null,
      count: 0,
    }
  },
  props: {
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.myChart_graphics()
        this.customers()
      },
      deep: true,
    },
  },
  // dom加载完成后
  mounted() {
    this.myChart_graphics()
    // 颐伦养老服务机构全国分布图
    // this.distributionmap()
    this.customers()
    this.getName()
    this.getLocation()
    //获取服务老人数
    setInterval(() => {
      this.customers()
    }, 60000)
  },
  // 定义函数
  methods: {
    getName() {},
    // 请求获取老人
    customers() {
      customers(this.levelAndCode ? this.levelAndCode : null).then((res) => {
        let regker = /(?!^)(?=(\d{3})+$)/g
        console.log(this.levelAndCode)
        if (this.levelAndCode) {
          if (this.levelAndCode.level == 'PROVINCE') {
            if (this.provinceName == '四川省') {
              this.numdekr = (712622).toString().replace(regker, ',')
            } else if (this.provinceName == '云南省') {
              this.numdekr = (203606).toString().replace(regker, ',')
            } else {
              this.numdekr = (0).toString().replace(regker, ',')
            }
          } else if (this.levelAndCode.level == 'CITY') {
            if (this.cityName == '成都市') {
              this.numdekr = (170357).toString().replace(regker, ',')
            } else if (this.cityName == '达州市') {
              this.numdekr = (54281).toString().replace(regker, ',')
            } else if (this.cityName == '广安市') {
              this.numdekr = (36326).toString().replace(regker, ',')
            } else if (this.cityName == '乐山市') {
              this.numdekr = (34839).toString().replace(regker, ',')
            } else if (this.cityName == '眉山市') {
              this.numdekr = (32912).toString().replace(regker, ',')
            } else if (this.cityName == '南充市') {
              this.numdekr = (65075).toString().replace(regker, ',')
            } else if (this.cityName == '雅安市') {
              this.numdekr = (13845).toString().replace(regker, ',')
            } else if (this.cityName == '自贡市') {
              this.numdekr = (30307).toString().replace(regker, ',')
            } else if (this.cityName == '红河哈尼族彝族自治州') {
              this.numdekr = (30025).toString().replace(regker, ',')
            } else if (this.cityName == '昆明市') {
              this.numdekr = (55093).toString().replace(regker, ',')
            } else if (this.cityName == '丽江市') {
              this.numdekr = (8182).toString().replace(regker, ',')
            } else {
              this.numdekr = (0).toString().replace(regker, ',')
            }
          }
        } else {
          this.numdekr = (1018032).toString().replace(regker, ',')
        }
      })
    },
    /**
     * formatNumber()
     * 将数值四舍五入后格式化.
     *
     * @param num 数值(Number或者String)
     * @param cent 要保留的小数位(Number)
     * @param isThousand 是否需要千分位 0:不需要, 1:需要(数值类型);
     * @return 格式的字符串,如'1,234,567.45'
     * @type String
     */
    formatNumber(num, cent, isThousand) {
      num = num.toString().replace(/\$|\,/g, '')
      if (isNaN(num)) num = '0'
      var sign = num == (num = Math.abs(num))
      num = parseFloat(num.toFixed(cent))
      num = num.toString()
      var cents
      if (num.lastIndexOf('.') != -1) {
        let index = num.lastIndexOf('.')
        cents = num.substring(index + 1, num.length)
      } else {
        cents = ''
      }
      num = Math.floor(num * Math.pow(10, cent) + 0.50000000001)
      num = Math.floor(num / Math.pow(10, cent)).toString()
      if (isThousand) {
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
          num =
            num.substring(0, num.length - (4 * i + 3)) +
            num.substring(num.length - (4 * i + 3))
      }
      if (cent > 0) {
        if (cents == '') {
          return (sign ? '' : '-') + num
        } else {
          return (sign ? '' : '-') + num + '.' + cents
        }
      } else {
        return (sign ? '' : '-') + num
      }
    },
    myChart_graphics() {
      perSonnel(this.levelAndCode ? this.levelAndCode : null).then((res) => {
        this.careWorker = res.data.data
        let myGraphics = echarts.init(document.getElementById('graphics'))
        var m2R2Data = [
          {
            value: 6,
            legendname: '医生',
            name: '医生(6人)',
            itemStyle: { color: '#A568FF' },
          },
          {
            value: 12,
            legendname: '护士',
            name: '护士(12人)',
            itemStyle: { color: '#FF8A00' },
          },
          {
            value: 28,
            legendname: '护工',
            name: '社工(28人)',
            itemStyle: { color: '#5CE004' },
          },
          {
            value: this.careWorker,
            legendname: '护理员',
            name: '护理员(' + this.careWorker + '人' + ')',
            itemStyle: { color: '#00DEFF' },
          },
          {
            value: 14,
            legendname: '营养师',
            name: '营养师(14人)',
            itemStyle: { color: '#F22E5D' },
          },
          {
            value: 14,
            legendname: '康复理疗师',
            name: '康复理疗师(14人)',
            itemStyle: { color: '#FFF404' },
          },
          {
            value: 14,
            legendname: '健康管理师',
            name: '健康管理师(14人)',
            itemStyle: { color: '#AB24EE' },
          },
        ]
        let cc = 0
        m2R2Data.map((item) => {
          cc = cc + item.value
        })
        m2R2Data.forEach((v) => {
          v.name = v.name + ((v.value / cc) * 100).toFixed(1) + '%'
        })
        let optionage = {
          title: {
            text: cc + '人',
            left: '19%',
            top: '37%',
            textStyle: {
              color: '#fff',
              fontSize: 20,
              align: 'center',
            },
          },
          tooltip: {
            trigger: 'item',
            show: false,
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            left: '50%',
            align: 'left',
            icon: 'circle',
            top: '15%',
            textStyle: {
              color: '#ffffff',
              fontSize: 14,
            },
            height: 250,
          },
          series: [
            {
              type: 'pie',
              center: ['27%', '42%'],
              radius: ['35%', '50%'],
              clockwise: true, //饼图的扇区是否是顺时针排布
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'outter',
                },
              },
              emphasis: {
                label: {
                  show: true,
                  length: 15,
                  length2: 15,
                  formatter: function (parms) {
                    return (
                      parms.value + '人' + '\n' + parms.percent.toFixed(1) + '%'
                    )
                  },
                },
              },
              data: m2R2Data,
            },
          ],
        }
        myGraphics.setOption(optionage)
      })
    },
    back() {
      console.log('001dd')
      const cityName = sessionStorage.getItem('cityName')
      const provinceName = sessionStorage.getItem('provinceName')
      if (provinceName) {
        if (cityName) {
          this.handleToProvince()
        } else {
          let response = require('../../assets/json/map/' + chinaId + '.json')
          const mapJson = response
          chinaJson = mapJson
          myChart = echarts.init(document.getElementById('mapChart'))
          registerAndsetOption(myChart, chinaId, chinaName, mapJson, false)
          this.count = 0
          this.provinceName = null
          this.cityName = null
          sessionStorage.removeItem('provinceName')
          sessionStorage.removeItem('cityName')
          this.$emit('changeName', 0, '颐伦养老服务集团有限公司')
        }
      }
    },
    /**
     * Echarts地图
     */
    mapChart(divid) {
      this.provinceName = sessionStorage.getItem('provinceName')
      this.cityName = sessionStorage.getItem('cityName')
      if (this.provinceName) {
        if (this.cityName) {
          this.$emit('changeName', 2, this.cityName, {
            level: 'CITY',
            code: cityMap[this.cityName],
          })
        } else {
          this.$emit('changeName', 1, this.provinceName, {
            level: 'PROVINCE',
            code: cityMap[this.provinceName],
          })
        }
      }
      myChart = echarts.init(document.getElementById(divid))
      if (this.provinceName) {
        if (this.cityName) {
          this.$nextTick(() => {
            let cityId = cityMap[this.cityName]
            let responses = require('../../assets/json/map/' + cityId + '.json')
            const mapJson = responses
            myChart = echarts.init(document.getElementById('mapChart'))
            registerAndsetOption(myChart, cityId, this.cityName, mapJson, true)
            this.count = 2
          })
        } else {
          this.handleToProvince()
        }
      } else {
        let response = require('../../assets/json/map/' + chinaId + '.json')
        console.log(response)
        const mapJson = response
        chinaJson = mapJson
        registerAndsetOption(myChart, chinaId, chinaName, mapJson, false)
        parentId = chinaId
        parentName = 'china'
        this.count = 0
      }
      myChart.on('click', (param) => {
        var cityId = cityMap[param.name]
        this.count++
        if (this.count === 1) {
          this.provinceName = param.name
          sessionStorage.setItem('provinceName', param.name)
          this.$emit('changeName', 1, param.name, {
            level: 'PROVINCE',
            code: cityMap[param.name],
          })
          if (
            param.name == '北京市' ||
            param.name == '天津市' ||
            param.name == '上海市' ||
            param.name == '重庆市'
          ) {
            this.count++
          }
          try {
            require('../../assets/json/map/' + cityId + '.json')
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '该区域暂无数据请联系工作人员处理',
            })
            this.count--
            return
          }
        } else if (this.count === 2) {
          try {
            require('../../assets/json/map/' + cityId + '.json')
            this.cityName = param.name
            sessionStorage.setItem('cityName', param.name)
            this.$emit('changeName', 2, param.name, {
              level: 'CITY',
              code: cityMap[param.name],
            })
          } catch (error) {
            this.$message({
              type: 'warning',
              message: '该区域暂无数据请联系工作人员处理',
            })
            this.count--
            return
          }
        } else if (this.count > 2) {
          if (cityId) {
            this.$router.push(
              '/districtCounty?code=' +
                cityMap[param.name] +
                '&name=' +
                param.name
            )
          } else {
            this.$message({
              type: 'warning',
              message: '该区域暂无数据请联系工作人员处理',
            })
          }
          return
        }
        if (cityId) {
          //代表有下级地图
          let responses
          responses = require('../../assets/json/map/' + cityId + '.json')
          const mapJson = responses
          registerAndsetOption(myChart, cityId, param.name, mapJson, true)
        } else {
          return
        }
      })
    },
    handleToChina() {
      let response = require('../../assets/json/map/' + chinaId + '.json')
      const mapJson = response
      chinaJson = mapJson
      myChart = echarts.init(document.getElementById('mapChart'))
      registerAndsetOption(myChart, chinaId, chinaName, mapJson, false)
      this.count = 0
      this.provinceName = null
      this.cityName = null
      sessionStorage.removeItem('provinceName')
      sessionStorage.removeItem('cityName')
      this.$emit('changeName', 0, '颐伦养老服务集团有限公司')
    },
    handleToProvince() {
      var cityId = cityMap[this.provinceName]
      let responses = require('../../assets/json/map/' + cityId + '.json')
      const mapJson = responses
      myChart = echarts.init(document.getElementById('mapChart'))
      registerAndsetOption(myChart, cityId, this.provinceName, mapJson, true)
      if (
        this.provinceName == '北京市' ||
        this.provinceName == '天津市' ||
        this.provinceName == '上海市' ||
        this.provinceName == '重庆市'
      ) {
        this.count = 2
      } else {
        this.count = 1
      }
      this.cityName = null
      sessionStorage.removeItem('cityName')
      this.$emit('changeName', 1, this.provinceName, {
        level: 'PROVINCE',
        code: cityMap[this.provinceName],
      })
    },
    handleToCity() {},
    getLocation() {
      getScreenArea().then((res) => {
        if (res.data.code == 0) {
          areaData = this.flatTree(res.data.data)
          this.mapChart('mapChart')
        }
      })
    },
    flatTree(treeData) {
      let result = []
      treeData.forEach((item) => {
        // 先克隆一份数据作为第一层级的填充
        let res = JSON.parse(JSON.stringify(item))
        delete res.children
        delete res.parentId
        delete res.weight
        delete res.id
        res.itemStyle = {
          areaColor: 'rgba(27,255,224,.25)',
          color: '#ccc',
          //   borderColor: '#DC7F42',
          //   borderWidth: 1,
          // borderType: 'dashed'
          //   shadowColor: '#DC7F42',
          //   shadowBlur: 10,
        }
        result.push(res)
        if (item.children && item.children.length > 0) {
          // 如果当前children为数组并且长度大于0，才可进入flatTree()方法
          result = result.concat(this.flatTree(item.children))
        }
      })
      return result
    },
  },
}
/**
 *
 * @param {*} myChart    Echart实例
 * @param {*} id        省市县Id
 * @param {*} name      省市县名称
 * @param {*} mapJson   地图Json数据
 * @param {*} flag      是否往mapStack里添加parentId，parentName
 */
function registerAndsetOption(myChart, id, name, mapJson, flag) {
  echarts.registerMap(name, mapJson)
  myChart.setOption({
    geo: {
      type: 'map',
      map: name,
      //   right: '10%',
      top: id === 100000 ? '30%' : '10%',
      //   bottom: '50%',
      roam: false,
      center: undefined,
      itemStyle: {
        normal: {
          areaColor: '#0C0B50',
          borderColor: '#5667A0',
          borderWidth: 1,
        },
      },
      zoom: id === 100000 ? 1.4 : 1.2,
      //   silent: true,
      data: initMapData(mapJson),
      regions: areaData,
      emphasis: {
        itemStyle: {
          areaColor: '#0C0B36',
          borderColor: '#1DA895',
          borderWidth: 1,
        },
      },
      label: {
        emphasis: {
          show: true, //开启悬浮事件
          color: '#fff', //字体颜色
          //   backgroundColor: "red"
          position: 'inside',
        },
      },
    },
    series: [
      {
        type: 'map',
        roam: false,
        nameProperty: 'ADCODE',
        // coordinateSystem: "geo",
        // data: data,
        emphasis: {
          boolean: false,
          itemStyle: {
            areaColor: '#fff',
          },
        },
        itemStyle: {
          normal: {
            areaColor: '#fff',
            borderColor: '#fff',
            borderWidth: 1.5,
          },
        },
      },
    ],
  })

  if (flag) {
    //往mapStack里添加parentId，parentName,返回上一级使用
    mapStack.push({
      mapId: parentId,
      mapName: parentName,
    })
    parentId = id
    parentName = name
  }
}

function initMapData(mapJson) {
  var mapData = []
  for (var i = 0; i < mapJson.features.length; i++) {
    mapData.push({
      name: mapJson.features[i].properties.name,
      code: mapJson.features[i].properties.adcode
        ? mapJson.features[i].properties.adcode
        : mapJson.features[i].id,
    })
  }
  return mapData
}
</script>

<style scoped>
.top-title {
  font-size: 0.7rem;
  line-height: 1.4rem;
  text-align: center;
  height: 0.99rem;
}

.top-title span {
  font-weight: bold;
  font-size: 20px;
  background-image: -webkit-linear-gradient(
    92deg,
    #0072ff 0%,
    #0072ff 0%,
    #00eaff 49%,
    #01aaff 100%,
    #01aaff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.map_box {
  height: 100%;
  width: 100%;
}
/* #mapChart {
  width: 100%;
  height: 100%;
} */
.hide {
  display: none;
}
.show {
  display: block;
}
#gobtn {
  position: absolute;
  bottom: 0;
  right: 0;
}
/* 滚动插件 */
.warp {
  height: 90%;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}
.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.warp ul li,
a {
  display: block;
  height: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  font-size: 0.5rem;
  color: #22bfe0;
}
.warp ul li span:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.warp ul li > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* 颐伦养老服务机构全国分布图 */
.map {
  background: url('../../image/top/mapback.png') 0 0 no-repeat;
  background-size: 100% 100%;
  height: 28.3rem;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.map-title {
  text-align: center;
}
.map-title img {
  width: 66.5%;
}
.map .icon-cube {
  color: #68d8fe;
}
.map .chart {
  flex: 1;
}
.map .geo {
  width: 100%;
  height: 100%;
  position: relative;
}
.geogonsi {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  width: 17rem;
}
.geogonsi > ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: rgba(207, 1, 65, 0.3);
  font-size: 0.1rem;
}
.geogonsi > ul > li {
  color: rgba(244, 244, 244, 0.7);
  margin-bottom: 0.6rem;
  margin-right: 0.5rem;
}
.gonsi {
  text-align: center;
  font-size: 0.6rem;
}
.geogonsi > ul > li > p {
  line-height: 0.6rem;
}
.total {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999999999999;
  width: 12rem;
}
.total > div {
  text-align: center;
  color: #fffffd;
  font-size: 0.7rem;
  line-height: 1.3rem;
}
.total > ul {
  display: flex;
  color: #fff;
  justify-content: center;
}
.total > ul > li {
  padding: 0.3rem 0.2rem;
  font-size: 0.7rem;
  background: #0079fe;
  margin-right: 0.3rem;
  border-radius: 0.2rem;
}
/* 分布图·热词 */
.users {
  height: 12.2rem;
  display: flex;
  justify-content: space-between;
}
.users > .inner {
  /* width: 17rem; */
  width: 49.55%;
  height: 100%;
}
/* 颐伦加会员服务内容分布图 */
.users .chart {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('../../image/top/rencai.png') 0 0 no-repeat;
  background-size: 100% 100%;
}
.users .hotwords {
  width: 100%;
  height: 100%;
  background: url('../../image/top/fuwurenyuan2.png') 0 0 no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.users .inname {
  color: #00a0e8;
  font-size: 0.8rem;
  line-height: 1.7rem;
  text-indent: 1rem;
}
.users .inul {
  display: flex;
  width: 100%;
  height: 95%;
}
.users .inul > li {
  flex: 1;
}
/* 颐伦会员服务热词 */
.servicehotwords {
  height: 10.4rem;
  width: 100%;
  position: relative;
}
.retsiment {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}
.provide-aged {
  background-image: url(../../../src/image/top/top.png);
  background-size: 100% 100%;
  width: 5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.provide-ageds {
  width: 5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #ef8b42;
  border-radius: 4px;
}
.training {
  color: #c2dbfa;
  font-size: 1rem;
}
.personnel {
  color: #9bd1ff;
  font-size: 0.4rem;
  margin-top: 0.5rem;
}
.trainingage {
  color: #ef8b42;
  font-size: 1rem;
}
.personnelage {
  color: #ef8b42;
  font-size: 0.4rem;
  margin-top: 0.5rem;
}
.fwryzs {
  position: absolute;
  top: 79%;
  left: 13%;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}
.contenty {
  background: url(../../image/top/laorenshu.png) 0 0 no-repeat;
  background-size: 100% 100%;
  height: 3.5rem;
  box-sizing: border-box;
  padding-left: 0.5rem;
}
.govement {
  font-family: PingFang SC;
  font-weight: 500;
  color: #7fb5f6;
  font-size: 0.5rem;
  line-height: 1rem;
  display: block;
  margin-bottom: 0.3rem;
}
.updata {
  font-size: 24px;
  font-weight: bold;
  color: #38daf2;
}
.managers:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0.6rem;
}
.managers:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0.6rem;
}
.numder-res {
  background: url('../../image/top/rencaipeixun1.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 4.5rem;
  height: 4.56rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.numder-res1 {
  background: url('../../image/top/rencaipeixun3.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 4.5rem;
  height: 4.85rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.volunteers {
  background: url('../../image/top/rencaipeixun2.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 167px;
  height: 4.56rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.volunteers1 {
  background: url('../../image/top/rencaipeixun4.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 167px;
  height: 4.85rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ds-tit {
  color: #c2dbfa;
  font-size: 1rem;
}
.ds-num {
  color: #9bd1ff;
  font-size: 0.4rem;
  margin-top: 0.5rem;
}
.ds-tit1 {
  color: #e58541;
  font-size: 1rem;
}
.ds-num1 {
  color: #ef8b42;
  font-size: 0.4rem;
  margin-top: 0.5rem;
}
.ds-border {
  z-index: 99999999999999999 !important;
  position: absolute;
  top: 5%;
  right: 1%;
  display: flex;
  align-items: center;
}
.ds-zkk {
  width: 7px;
  height: 100px;
}
.ds-ykk {
  width: 7px;
  height: 100px;
}

.navigation {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff6c00;
  position: absolute;
  bottom: 0.4rem;
  left: 0.5rem;
  z-index: 999999999999;
}
.navigation > span {
  cursor: pointer;
}
</style>
