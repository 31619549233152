<template>
  <div id="app">
    <!-- 路由容器 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // 页面加载时候调用
    this.selfadaption()
  },
  methods: {
    //自调用函数
    selfadaption() {
      // 1、页面一加载就要知道页面宽度计算
      var setFont = function () {
        // 因为要定义变量可能和别的变量相互冲突，污染，所有用自调用函数
        var html = document.documentElement // 获取html
        // 获取宽度
        var width = html.clientWidth

        // 判断
        if (width < 1024) width = 1024
        if (width > 1920) width = 1920
        // 设置html的基准值
        var fontSize = width / 80 + 'px'
        // 设置给html
        html.style.fontSize = fontSize
      }
      setFont()
      // 2、页面改变的时候也需要设置
      // 尺寸改变事件
      window.onresize = function () {
        setFont()
      }
    },
  },
}
</script>

<style scoped lang="scss">
#app {
  height: 100%;
  width: 100%;
}
</style>
