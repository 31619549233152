// token
const TOKEN = 'statistics-ins-token'
// tenantNo
const TENANTNO = 'statistics-ins-tenantNo'

export function setToken(token) {
  return sessionStorage.setItem(TOKEN, token)
}
export function getToken() {
  return sessionStorage.getItem(TOKEN)
}
export function removeToken() {
  return sessionStorage.removeItem(TOKEN)
}

// 保存tenantNo到cookie中
export function setTenantNo(tenantNo) {
  return sessionStorage.setItem(TENANTNO, tenantNo)
}
// 获取到cookie中的tenantNo
export function getTenantNo() {
  return sessionStorage.getItem(TENANTNO)
}
// cookie中删除token
export function removeTenantNo() {
  return sessionStorage.removeItem(TENANTNO)
}
