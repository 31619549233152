<template>
  <div class="homeCare">
    <div class="homeCare-line">
      <div class="homeCare-line-item">
        <div class="cumulative-total">
          <!-- {{ serviceTotal | moneyDetail }} -->
          <span>{{ serviceTotal | tenThousand }}</span>
        </div>
        <div class="cumulative-desc">累计服务人次</div>
      </div>
      <div class="homeCare-line-item">
        <div class="cumulative-total">
          <span>{{ total | tenThousand }}</span>
        </div>
        <div class="cumulative-desc">累计订单总数</div>
      </div>
      <div class="homeCare-line-item">
        <div class="cumulative-total">
          <!-- {{ amount | moneyDetail }} -->
          <span>{{ amount | moneyDetail }} </span>
        </div>
        <div class="cumulative-desc">累计订单金额</div>
      </div>
    </div>
    <div class="homeCare-line">
      <div>
        <img src="../../../image/left/dayAdd.png" alt="" />
      </div>
      <div class="new-add-item">
        <div class="newAddCount">{{ serviceMode.curServiceTotal }}</div>
        <div class="newAddDesc">服务人次</div>
      </div>
      <div class="new-add-item">
        <div class="newAddCount">{{ serviceMode.curTotal }}</div>
        <div class="newAddDesc">订单数量</div>
      </div>
      <div class="new-add-item">
        <div class="newAddCount">
          {{ serviceMode.curAmount | moneyDetail }}
          <!-- {{ 2222222222222 | moneyDetail }} -->
        </div>
        <div class="newAddDesc">订单金额</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getServiceModel } from '@/api/allData'

export default {
  data() {
    return {
      serviceMode: {
        /**
         * 累计订单消费金额
         */
        amount: 0,
        /**
         * 今日新增：消费金额
         */
        curAmount: 0,
        /**
         * 今日新增：服务人数
         */
        curServiceTotal: 0,
        /**
         * 今日新增：订单
         */
        curTotal: 0,
        /**
         * 累计服务总人数
         */
        serviceTotal: 0,
        /**
         * 累计订单数
         */
        total: 0,
      },
      serviceTotal: 0,
      total: 0,
      amount: 0,
    }
  },
  created() {
    this.init()
  },
  props: {
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  methods: {
    async init() {
      const res = await getServiceModel(
        this.levelAndCode ? this.levelAndCode : null
      )
      if (res.data.code == 0) {
        this.serviceMode = res.data.data
      }
      const provinceName = sessionStorage.getItem('provinceName')
      const cityName = sessionStorage.getItem('cityName')
      if (this.levelAndCode) {
        if (this.levelAndCode.level == 'PROVINCE') {
          if (provinceName == '四川省') {
            this.serviceTotal = 13891384 * 2
            this.total = 1736423 * 2
            this.amount = 150577969 * 2
          } else if (provinceName == '云南省') {
            this.serviceTotal = 2211320 * 2
            this.total = 276415 * 2
            this.amount = 5436325 * 2
          } else {
            this.serviceTotal = 0
            this.total = 0
            this.amount = 0
          }
        } else if (this.levelAndCode.level == 'CITY') {
          if (cityName == '成都市') {
            this.serviceTotal = 564000 * 2
            this.total = 70000 * 2
            this.amount = 2830000 * 2
          } else if (cityName == '达州市') {
            this.serviceTotal = 794856 * 2
            this.total = 99357 * 2
            this.amount = 16750000 * 2
          } else if (cityName == '德阳市') {
            this.serviceTotal = 178560 * 2
            this.total = 22320 * 2
            this.amount = 2849000 * 2
          } else if (cityName == '广安市') {
            this.serviceTotal = 138000 * 2
            this.total = 17000 * 2
            this.amount = 1299000 * 2
          } else if (cityName == '乐山市') {
            this.serviceTotal = 508872 * 2
            this.total = 63609 * 2
            this.amount = 13428330 * 2
          } else if (cityName == '凉山彝族自治州') {
            this.serviceTotal = 168000 * 2
            this.total = 21000 * 2
            this.amount = 2793000 * 2
          } else if (cityName == '眉山市') {
            this.serviceTotal = 4456000 * 2
            this.total = 557000 * 2
            this.amount = 51509000 * 2
          } else if (cityName == '南充市') {
            this.serviceTotal = 292000 * 2
            this.total = 36560 * 2
            this.amount = 5901000 * 2
          } else if (cityName == '内江市') {
            this.serviceTotal = 117000 * 2
            this.total = 14640 * 2
            this.amount = 2196000 * 2
          } else if (cityName == '遂宁市') {
            this.serviceTotal = 250000 * 2
            this.total = 31000 * 2
            this.amount = 3265000 * 2
          } else if (cityName == '雅安市') {
            this.serviceTotal = 241232 * 2
            this.total = 30000 * 2
            this.amount = 4582500 * 2
          } else if (cityName == '宜宾市') {
            this.serviceTotal = 1847000 * 2
            this.total = 230500 * 2
            this.amount = 17150000 * 2
          } else if (cityName == '资阳市') {
            this.serviceTotal = 585000 * 2
            this.total = 73000 * 2
            this.amount = 12905000 * 2
          } else if (cityName == '自贡市') {
            this.serviceTotal = 3748000 * 2
            this.total = 468505 * 2
            this.amount = 13117000 * 2
          } else if (cityName == '红河哈尼族彝族自治州') {
            this.serviceTotal = 2210000 * 2
            this.total = 276000 * 2
            this.amount = 5106325 * 2
          } else if (cityName == '昆明市') {
            this.serviceTotal = 880 * 2
            this.total = 110 * 2
            this.amount = 330000 * 2
          } else {
            this.serviceTotal = 0
            this.total = 0
            this.amount = 0
          }
        }
      } else {
        this.serviceTotal = 16102704 * 2
        this.total = 2012838 * 2
        this.amount = 156014294 * 2
      }
    },
  },
}
</script>

<style scoped>
.homeCare {
  position: relative;
  height: 12.2rem;
  margin-bottom: 0.4rem;
  background: url(../../../image/left/homeCare.png) 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: space-evenly;
}
.homeCare-line {
  width: 95%;
  display: flex;
}
.homeCare-line:nth-child(1) {
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
  height: 4.583333rem;
}
.homeCare-line:nth-child(2) {
  align-items: center;
  justify-content: space-around;
  height: 4.75rem;
  box-sizing: border-box;
  background: rgba(14, 18, 67, 0.5);
  border: 1px solid rgba(104, 101, 196, 0.5);
}
.homeCare-line > .homeCare-line-item {
  width: 6.7916667rem;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
}
.homeCare-line > .homeCare-line-item:nth-child(1) {
  background: url(../../../image/left/cumulative-service.png) 0 0 no-repeat;
  background-size: 100% 100%;
}
.homeCare-line > .homeCare-line-item:nth-child(2) {
  background: url(../../../image/left/cumulative-service.png) 0 0 no-repeat;
  background-size: 100% 100%;
}
.homeCare-line > .homeCare-line-item:nth-child(3) {
  background: url(../../../image/left/cumulative-money.png) 0 0 no-repeat;
  background-size: 100% 100%;
}
.cumulative-total {
  font-size: 1rem;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: #ffffff;
}
.cumulative-desc {
  font-size: 0.583333333333rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8ec0ec;
}
.new-add-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newAddCount {
  text-align: center;
  font-size: 1.25rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffab00;
}
.newAddDesc {
  font-size: 0.583333rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8ec0ec;
}
</style>
