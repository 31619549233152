import Vue from 'vue'
import Router from 'vue-router'
import nationalbigscreen from '../page/nationalbigscreen'
Vue.use(Router)

export const constantRoutes = [
  // 主体大屏
  {
    path: '/',
    name: 'nationalbigscreen',
    component: nationalbigscreen,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../page/login/index.vue'),
  },
  {
    path: '/insComplex',
    name: 'insComplex',
    component: () => import('../page/insComplex'),
  },
  {
    path: '/districtCounty',
    name: 'districtCounty',
    component: () => import('../page/districtCounty'),
  },
  {
    path: '/serviceOrder',
    name: 'serviceOrder',
    component: () =>
      import('../page/districtCounty/middle/components/orderDetail.vue'),
  },
  {
    path: '/governmentAgency',
    name: 'governmentAgency',
    component: () => import('../page/governmentAgency'),
  },
  {
    path: '/videopage',
    name: 'videooage',
    component: () => import('../page/videopage/videopage'),
  },
]
const createRouter = () =>
  new Router({
    // mode: 'history', // 需要服务支持
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  })
const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  // 重置路由器
  router.matcher = newRouter.matcher
}

export default router
