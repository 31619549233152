/**
 *
 *
 *
 * 政府全部数据
 * 获取政府大屏一级页面
 *
 */
// 引入封装的请求
import request from '@/utils/request'

// 获取到全部政府数据一级页面
// export function allDataObj() {
//     return request({
//         url: "/file/select/zGovernment",
//         method: "post",
//     });
// }

// 获取到实时的消费数据

export const getRealTimeConsumption = () =>
  request({
    url: '/v1/screen/list/orders',
    method: 'get',
  })

// 获取居家服务模式情况

export const getServiceModel = (params) =>
  request({
    url: '/v1/screen/statistics/service_mode',
    method: 'get',
    params: params,
  })

// 获取机构（社会居家）养老统计数据

export const getInstitutionStoreType = (params) =>
  request({
    url: '/v1/screen/statistics/store_type',
    method: 'get',
    params: params,
  })

// 获取颐伦会员服务内容分布图

export const getService = () =>
  request({
    url: '/v1/screen/list/services',
    method: 'get',
  })

// 大屏区域地址code

export const getScreenArea = () =>
  request({
    url: '/v1/screen/nation/area',
    method: 'get',
  })

// 获取服务人员概况

export const getWorker = () =>
  request({
    url: '/v1/screen/statistics/worker',
    method: 'get',
  })
// 获取服务人员总数
export const perSonnel = (params) =>
  request({
    url: '/v1/screen/statistics/service_staff',
    method: 'get',
    params: params,
  })
// 获取获取全部老人总数
export const customers = (params) =>
  request({
    url: '/v1/screen/statistics/customers',
    method: 'get',
    params: params,
  })
// 获取服务热词

export const getServiceHotWord = () =>
  request({
    url: '/v1/screen/hot/services',
    method: 'get',
  })

// 获取用户总数、月度、日均消费人数;会员总数、累计消费金额、人数

export const getStatisticsAll = () =>
  request({
    url: '/v1/screen/statistics/all',
    method: 'get',
  })
