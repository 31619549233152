// 登录-退出登录-获取用户信息
import { login, getInfo, logout } from '@/api/user'
// 获取token-保存token-清除token
import {
  getToken,
  setToken,
  removeToken,
  setTenantNo,
  removeTenantNo,
} from '@/utils/tools'
// 定义一个对象
const getDefaultState = () => {
  return {
    token: getToken(),
  }
}
// 定义数据
const state = getDefaultState()

const mutations = {
  // 注销登录时候合并对象
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  // 保存token
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  // 保存用户权限
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
}

const actions = {
  // 用户登录
  login({ commit }, userInfo) {
    // 解构用户名和密码
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username, password: password })
        .then((response) => {
          console.log('获取到的登录数据', response)
          let _data = response.data.data
          //判断是否接口成功
          if (response.data.code == 0) {
            //获取到的token
            let token = _data.token_type + _data.access_token
            //租户号
            //保存到vuex中
            commit('SET_TOKEN', token)
            //将token保存在cookies中
            setToken(token)
            setTenantNo(_data.account.tenantNo)
            //通过
            resolve(response)
          } else {
            //通过
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 获取用户信息
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      // 发送请求
      getInfo()
        .then((response) => {
          // 解构获取的数据
          const data = response.data
          // 判断是否有数据
          if (!data) {
            return reject('验证失败，请重新登录')
          }
          // 通过
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 用户退出登录
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          // 必须先删除令牌
          removeToken()
          // 注销登录时候合并对象
          commit('RESET_STATE')
          // 清除租户号
          removeTenantNo()
          // 通过
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 删除令牌
  resetToken({ commit }) {
    return new Promise((resolve) => {
      // 必须先删除令牌
      removeToken()
      // 注销登录时候合并对象
      commit('RESET_STATE')
      resolve()
    })
  },
}

export default {
  // 这个属性可以在页面中触发异步函数时候,可写入url,而不需要写函数名
  namespaced: true,
  state,
  mutations,
  actions,
}
