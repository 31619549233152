<template>
  <!-- 将整个大屏分成三部分 -->
  <div class="viewport">
    <!-- 头部 -->
    <ul class="header_top" style="display: flex; align-items: flex-start">
      <img
        src="../image/top/back.png"
        v-if="provinceName || cityName"
        alt=""
        style="
          float: left;
          padding-top: 0.4rem;
          margin-right: 0.2rem;
          cursor: pointer;
        "
        @click="handleBack"
      />
      <ul style="height: 100%; float: left; flex: 1">
        <li class="header-top-title">
          <div class="top-title">
            {{
              cityName
                ? cityName
                : provinceName
                ? provinceName
                : '颐伦养老服务集团有限公司'
            }}
          </div>
          <div style="cursor: pointer">
            <img
              v-if="isFullscreen"
              src="../image/top/guanbi.png"
              alt=""
              @click="buttoncli"
            />
            <img
              v-else
              src="../image/top/quanping.png"
              alt=""
              @click="buttoncli"
            />
            <img
              src="../image/login/loginOut.png"
              alt=""
              style="margin-left: 5px"
              @click="logout"
            />
          </div>
        </li>
        <li>
          <ul class="times_top">
            <li>{{ nowDate }}</li>
            <li>{{ nowWeek }}</li>
            <li>{{ nowTime }}</li>
          </ul>
        </li>
      </ul>
    </ul>
    <!-- 主体内容 -->
    <div class="viewmain">
      <leftcontent ref="leftcontent" :levelAndCode="levelAndCode"></leftcontent>
      <centercontent
        ref="centerMain"
        @changeName="changeName"
        :levelAndCode="levelAndCode"
      ></centercontent>
      <rightcontent
        ref="rightcontent"
        :levelAndCode="levelAndCode"
      ></rightcontent>
    </div>
  </div>
</template>

<script>
// 引入全屏模块
import screenfull from 'screenfull'
// 引入主题内容模块
import leftcontent from './maincontent/leftcontent'
import centercontent from './maincontent/centercontent'
import rightcontent from './maincontent/rightcontent/index.vue'
export default {
  //注册组件
  components: {
    leftcontent,
    centercontent,
    rightcontent,
  },
  // 定义数据
  data() {
    return {
      //时间
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      //全屏
      isFullscreen: false,
      provinceName: null,
      cityName: null,
      levelAndCode: null,
    }
  },
  // dom加载完成后
  mounted() {
    //刚加载就启动计时器
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)
    // 颐伦家会员服务内容分布图
    // this.serviceContent()
    // 颐伦养老机构实时数据-床位数据及服务人员数据
    this.bedPersonnelData()
    this.getName()
  },
  methods: {
    testClick() {
      console.log('执行了')
      this.$router.push({
        path: '/districtCounty',
      })
    },
    // 时间
    setNowTimes() {
      let myDate = new Date()
      // console.log(myDate)
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm =
        myDate.getMonth() + 1 < 10
          ? '0' + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1
      let dd = String(
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      )
      let hou = String(
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      )
      let min = String(
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      )
      let sec = String(
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      )
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },
    // 全屏
    buttoncli() {
      //   判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
    // 颐伦养老机构实时数据-床位数据及服务人员数据
    bedPersonnelData() {
      // 在这儿进行接口提交
      this.$store.dispatch('leftcontent/bedPersonnelData')
    },
    getName() {
      this.provinceName = sessionStorage.getItem('provinceName')
      this.cityName = sessionStorage.getItem('cityName')
    },
    handleBack() {
      this.$refs['centerMain'].back()
    },
    changeName(level, name, levelAndCode) {
      console.log(level, name, levelAndCode)
      if (level == 1) {
        this.provinceName = name
        this.cityName = null
      } else if (level == 2) {
        this.cityName = name
      } else if (level == 0) {
        this.provinceName = null
        this.cityName = null
      }
      this.levelAndCode = levelAndCode
    },
    logout() {
      this.$confirm('您确认要退出登录吗?', '退出登录?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.$message({
            type: 'success',
            message: '退出登录成功',
          })
          await this.$store.dispatch('user/logout')
          this.$router.push(`/login?redirect=/`)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录',
          })
        })
    },
  },
  //在主件摧毁前
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style>
/* 全局样式 */
body {
  line-height: 1.15;
  font-size: 0.5rem;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0 0 / cover;
  background-color: #101129;
}
* {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.viewport {
  background: url(../image/top/bigbackground3.png) no-repeat 0 0 / contain;
  background-size: 100% 100%;
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
}
/* 头部 */
.header_top {
  box-sizing: border-box;
  background: url(../image/top/bigbackground2.png) no-repeat 0 0 / contain;
  background-size: 100% 100%;
  width: 100%;
  height: 3.1rem;
  color: #a6bafd;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-top: 0.5rem;
}
.header-top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-title {
  font-size: 0.65rem;
}
.times_top {
  display: flex;
}
.times_top > li {
  margin-right: 0.5rem;
}
/* 主体内容 */
.viewmain {
  /* 限定大小 */
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
  display: flex;
  padding: 0 0.5rem 0;
}
.column {
  flex: 3.1;
  position: relative;
}
.column:nth-child(2) {
  flex: 4.8;
  margin: 0rem 0.3rem 0 0.3rem;
}
</style>
