//引入echarts
let echarts = require('echarts')

export default {
  data() {
    return {
      JosnList: [
        {
          name: '成都',
          value: '18000',
        },
        {
          name: '都江堰',
          value: '17900',
        },
        {
          name: '乐山',
          value: '17800',
        },
        {
          name: '眉山',
          value: '17700',
        },
        {
          name: '雅安',
          value: '17600',
        },
        {
          name: '宜宾',
          value: '17500',
        },
        {
          name: '泸州',
          value: '17400',
        },
        {
          name: '广安',
          value: '17300',
        },
        {
          name: '邛崃',
          value: '17200',
        },
        {
          name: '阆中',
          value: '17100',
        },
        {
          name: '自贡',
          value: '17000',
        },
        {
          name: '巴中',
          value: '16900',
        },
        {
          name: '昆明',
          value: '16800',
        },
        {
          name: '丽江',
          value: '16700',
        },
        {
          name: '大理',
          value: '16600',
        },
        {
          name: '景洪',
          value: '16500',
        },
        {
          name: '瑞丽',
          value: '16400',
        },
        {
          name: '保山',
          value: '16300',
        },
        {
          name: '芒市',
          value: '16200',
        },
        {
          name: '玉溪',
          value: '16100',
        },
        {
          name: '曲靖',
          value: '16000',
        },
        {
          name: '贵阳',
          value: '15900',
        },
        {
          name: '遵义',
          value: '15800',
        },
        {
          name: '赤水',
          value: '15700',
        },

        {
          name: '安顺',
          value: '15600',
        },
        {
          name: '兴义',
          value: '15500',
        },
        {
          name: '铜仁',
          value: '15400',
        },
        {
          name: '毕节',
          value: '15300',
        },

        {
          name: '毕节',
          value: '15300',
        },
        {
          name: '六盘水',
          value: '15200',
        },
        {
          name: '凯里',
          value: '15100',
        },
        {
          name: '海口',
          value: '15000',
        },
        {
          name: '三亚',
          value: '14900',
        },
        {
          name: '三沙',
          value: '14800',
        },
        {
          name: '儋州',
          value: '14700',
        },
        {
          name: '琼海',
          value: '14600',
        },
        {
          name: '杭州',
          value: '14500',
        },
        {
          name: '嘉兴',
          value: '14400',
        },
        {
          name: '绍兴',
          value: '14300',
        },
        {
          name: '台州',
          value: '14200',
        },
        {
          name: '湖州',
          value: '14200',
        },
        {
          name: '金华',
          value: '14100',
        },
        {
          name: '温州',
          value: '14000',
        },
        {
          name: '西宁',
          value: '13900',
        },
        {
          name: '格尔木',
          value: '13800',
        },
        {
          name: '德令哈',
          value: '13700',
        },
        {
          name: '玉树',
          value: '13600',
        },
        {
          name: '昭通',
          value: '13500',
        },
        {
          name: '腾冲',
          value: '13400',
        },
        {
          name: '泸水',
          value: '13300',
        },
        {
          name: '宣威',
          value: '13300',
        },
        {
          name: '香格里拉',
          value: '13200',
        },
        {
          name: '弥勒',
          value: '13100',
        },
        {
          name: '南充',
          value: '13000',
        },
        {
          name: '攀枝花',
          value: '12900',
        },
        {
          name: '绵阳',
          value: '12900',
        },
        {
          name: '绵阳',
          value: '12900',
        },
        {
          name: '咸阳',
          value: '12800',
        },
        // {
        //   name: '铜川',
        //   value: '12700',
        // },
        // {
        //   name: '渭南',
        //   value: '12600',
        // },
        // {
        //   name: '延安',
        //   value: '12500',
        // },
        // {
        //   name: '榆林',
        //   value: '12400',
        // },
        // {
        //   name: '汉中',
        //   value: '12300',
        // },
        // {
        //   name: '安康',
        //   value: '12200',
        // },
        // {
        //   name: '商洛',
        //   value: '12100',
        // },
        // {
        //   name: '宝鸡',
        //   value: '12000',
        // },
        // {
        //   name: '拉萨',
        //   value: '11900',
        // },
        // {
        //   name: '林周',
        //   value: '11800',
        // },
        // {
        //   name: '达孜',
        //   value: '11700',
        // },
        // {
        //   name: '堆龙德庆县',
        //   value: '11600',
        // },
        // {
        //   name: '尼木县',
        //   value: '11500',
        // },
        // {
        //   name: '当雄县',
        //   value: '11400',
        // },
        // {
        //   name: '曲水县',
        //   value: '11300',
        // },
        // {
        //   name: '墨竹工卡县',
        //   value: '11200',
        // },
        // {
        //   name: '林芝县',
        //   value: '11100',
        // },
        // {
        //   name: '米林县',
        //   value: '11000',
        // },
        // {
        //   name: '朗县',
        //   value: '10900',
        // },
        // {
        //   name: '工布江达县',
        //   value: '10800',
        // },
        // {
        //   name: '波密县',
        //   value: '10700',
        // },
        // {
        //   name: '察隅县',
        //   value: '10600',
        // },
        // {
        //   name: '墨脱县',
        //   value: '10500',
        // },
        // {
        //   name: '乃东县',
        //   value: '10400',
        // },
        // {
        //   name: '扎囊县',
        //   value: '10300',
        // },
        // {
        //   name: '贡嘎县',
        //   value: '10200',
        // },
        // {
        //   name: '桑日县',
        //   value: '10100',
        // },
        // {
        //   name: '琼结县',
        //   value: '10000',
        // },
        // {
        //   name: '呼和浩特',
        //   value: '9000',
        // },
        // {
        //   name: '包头',
        //   value: '8999',
        // },
        // {
        //   name: '乌海',
        //   value: '9998',
        // },
        // {
        //   name: '赤峰',
        //   value: '9997',
        // },
        // {
        //   name: '通辽',
        //   value: '9996',
        // },
        // {
        //   name: '鄂尔多斯',
        //   value: '9995',
        // },
        // {
        //   name: '呼伦贝尔',
        //   value: '9993',
        // },
        // {
        //   name: '巴彦淖尔',
        //   value: '9994',
        // },
        // {
        //   name: '乌兰察布',
        //   value: '9992',
        // },
        // {
        //   name: '霍林郭勒',
        //   value: '9991',
        // },
        // {
        //   name: '满洲里',
        //   value: '9990',
        // },
        // {
        //   name: '牙克石',
        //   value: '9989',
        // },
        // {
        //   name: '扎兰屯',
        //   value: '9988',
        // },
        // {
        //   name: '根河',
        //   value: '9987',
        // },
        // {
        //   name: '株洲',
        //   value: '9986',
        // },
        // {
        //   name: '湘潭',
        //   value: '9985',
        // },
        // {
        //   name: '衡阳',
        //   value: '9984',
        // },
        // {
        //   name: '邵阳',
        //   value: '9983',
        // },
        // {
        //   name: '岳阳',
        //   value: '9982',
        // },
        // {
        //   name: '常德',
        //   value: '9981',
        // },
        // {
        //   name: '张家界',
        //   value: '9980',
        // },
        // {
        //   name: '益阳',
        //   value: '9979',
        // },
        // {
        //   name: '郴州',
        //   value: '9978',
        // },
        // {
        //   name: '永州',
        //   value: '9977',
        // },
        // {
        //   name: '怀化',
        //   value: '9976',
        // },
        // {
        //   name: '娄底',
        //   value: '9975',
        // },
        // {
        //   name: '湘西',
        //   value: '9974',
        // },
        // {
        //   name: '苗族',
        //   value: '9973',
        // },
      ],
    }
  },
  mounted() {
    // 旅游旅居
    // this.sojournChart()
  },
  methods: {
    // 旅游旅居
    // sojournChart() {
    //   let that = this
    //   this.$nextTick(() => {
    //     // 基于准备好的dom，初始化echarts实例
    //     var myChart = echarts.init(document.getElementById('sojourn'))
    //     // 绘制图表
    //     myChart.setOption({
    //       title: {
    //         x: 'center',
    //         textStyle: {
    //           fontSize: 40,
    //         },
    //       },
    //       tooltip: {
    //         show: true,
    //       },
    //       series: [
    //         {
    //           type: 'wordCloud',
    //           sizeRange: [12, 16],
    //           width: '100%',
    //           height: '100%',
    //           rotationRange: [-45, 90],
    //           textPadding: 100,
    //           drawOutOfBound: false,
    //           rotationStep: 90,
    //           autoSize: {
    //             enable: true,
    //             minSize: 10,
    //           },
    //           gridSize: 0,
    //           textStyle: {
    //             normal: {
    //               color: function() {
    //                 return (
    //                   'rgb(' +
    //                   [
    //                     Math.round(Math.random() * 160),
    //                     Math.round(Math.random() * 160),
    //                     Math.round(Math.random() * 160),
    //                   ].join(',') +
    //                   ')'
    //                 )
    //               },
    //               color: '#7fb5f6',
    //               fontWeight: '600',
    //             },
    //             emphasis: {
    //               shadowBlur: 10,
    //               shadowColor: '#333',
    //             },
    //           },
    //           data: that.JosnList,
    //         },
    //       ],
    //     })
    //   })
    //   window.addEventListener('resize', function() {
    //     myChart.resize()
    //   })
    // },
  },
}
