<template>
  <div class="institutions">
    <div class="institutions-line">
      <div class="institutions-line-left">
        <div class="institutions-line-img">
          <img src="../../../image/left/institutionNum.png" alt="" />
        </div>
        <div class="institutions-line-content">
          <span class="institutions-count">{{ org.total + total }}</span>
          <span class="institutions-desc">机构数</span>
        </div>
      </div>
      <div class="institutions-line-left">
        <div class="institutions-line-img">
          <img src="../../../image/left/bedCount.png" alt="" />
        </div>
        <div class="institutions-line-content">
          <span class="institutions-count">{{ org.bedTotal + bedTotal }}</span>
          <span class="institutions-desc">床位总数</span>
        </div>
      </div>
    </div>
    <div class="institutions-line">
      <div class="institutions-line-left">
        <div class="institutions-line-img">
          <img src="../../../image/left/nurseBed.png" alt="" />
        </div>
        <div class="institutions-line-content">
          <span class="institutions-count">{{
            ((org.bedTotal + bedTotal)*0.51).toFixed()
          }}</span>
          <span class="institutions-desc">护理床位</span>
        </div>
      </div>
      <div class="institutions-line-left">
        <div class="institutions-line-img">
          <img src="../../../image/left/usualBed.png" alt="" />
        </div>
        <div class="institutions-line-content">
          <span class="institutions-count">{{
            ((org.bedTotal + bedTotal)*0.49).toFixed()
          }}</span>
          <span class="institutions-desc">普通床位</span>
        </div>
      </div>
    </div>
    <div class="institutions-line">
      <div class="institutions-line-left">
        <div class="institutions-line-img">
          <img src="../../../image/left/addBed.png" alt="" />
        </div>
        <div class="institutions-line-content">
          <span class="institutions-count">{{
            org.inBedTotal + inBedTotal
          }}</span>
          <span class="institutions-desc">总入住床位</span>
        </div>
      </div>
      <div class="institutions-line-left">
        <div class="institutions-line-img">
          <img src="../../../image/left/monthAdd.png" alt="" />
        </div>
        <div class="institutions-line-content">
          <span class="institutions-count">{{ org.monInBedTotal }}</span>
          <span class="institutions-desc">入住床位</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    org: {
      type: Object,
      default: {},
    },
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      const provinceName = sessionStorage.getItem('provinceName')
      const cityName = sessionStorage.getItem('cityName')
      if (this.levelAndCode) {
        if (this.levelAndCode.level == 'PROVINCE') {
          if (provinceName == '四川省') {
            this.total = 3
            this.bedTotal = 752
            this.nurseBedTotal = 202
            this.commonBedTotal = 550
            this.inBedTotal = 479
            //   this.inBedTotal = 0
          } else if (provinceName == '云南省') {
            this.total = 1
            this.bedTotal = 642
            this.nurseBedTotal = 183
            this.commonBedTotal = 459
            this.inBedTotal = 315
            //   this.inBedTotal = 0
          } else if (provinceName == '海南省') {
            this.total = 1
            this.bedTotal = 270
            this.nurseBedTotal = 54
            this.commonBedTotal = 216
            // this.inBedTotal = 2800
            this.inBedTotal = 202
          } else {
            this.total = 0
            this.bedTotal = 0
            this.nurseBedTotal = 0
            this.commonBedTotal = 0
            this.inBedTotal = 0
          }
        } else if (this.levelAndCode.level == 'CITY') {
          if (cityName == '乐山市') {
            this.total = 0
            this.bedTotal = 0
            this.nurseBedTotal = 0
            this.commonBedTotal = 0
            this.inBedTotal = 0
          } else if (cityName == '眉山市') {
            this.total = 1
            this.bedTotal = 179
            this.nurseBedTotal = 85
            this.commonBedTotal = 95
            this.inBedTotal = 140
          } else if (cityName == '自贡市') {
            this.total = 1
            this.bedTotal = 124
            this.nurseBedTotal = 25
            this.commonBedTotal = 99
            this.inBedTotal = 93
          } else if (cityName == '达州市') {
            this.total = 1
            this.bedTotal = 450
            this.nurseBedTotal = 90
            this.commonBedTotal = 360
            this.inBedTotal = 211
          } else if (cityName == '昆明市') {
            this.total = 0
            this.bedTotal = 121
            this.nurseBedTotal = 55
            this.commonBedTotal = 66
            this.inBedTotal = 40
          } else if (cityName == '丽江市') {
            this.total = 0
            this.bedTotal = 100
            this.nurseBedTotal = 44
            this.commonBedTotal = 56
            this.inBedTotal = 88
          } else if (cityName == '楚雄彝族自治州') {
            this.total = 1
            this.bedTotal = 330
            this.nurseBedTotal = 66
            this.commonBedTotal = 264
            this.inBedTotal = 125
          } else if (cityName == '曲靖市') {
            this.total = 1
            this.bedTotal = 91
            this.nurseBedTotal = 18
            this.commonBedTotal = 73
            this.inBedTotal = 62
          } else if (cityName == '海口市') {
            this.total = 1
            this.bedTotal = 270
            this.nurseBedTotal = 54
            this.commonBedTotal = 216
            this.inBedTotal = 202
          } else {
            this.total = 0
            this.bedTotal = 0
            this.nurseBedTotal = 0
            this.commonBedTotal = 0
            this.inBedTotal = 0
          }
        }
      } else {
        this.total = 5
        this.bedTotal = 1664
        this.nurseBedTotal = 439
        this.commonBedTotal = 1225
        this.inBedTotal = 996
      }
    },
  },
  data() {
    return {
      total: 0,
      bedTotal: 0,
      nurseBedTotal: 0,
      commonBedTotal: 0,
      inBedTotal: 0,
    }
  },
}
</script>

<style scoped>
/* 行 */

.institutions {
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 0.3rem;
  height: 14rem;
  background: url(../../../image/left/elderlyCareServiceCentre.png) 100% 100%
    no-repeat;
  /* background-size: 100% 100%; */
  /* height: 11.9rem; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.institutions > .institutions-line {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.institutions-line:nth-child(1) > .institutions-line-left {
  height: 100%;
  background: rgba(14, 18, 67, 0.5);
  box-sizing: border-box;
  border: 1px solid rgba(104, 101, 196, 0.5);
  width: 10.3rem;
  display: flex;
  padding-left: 1rem;
  align-items: center;
}
.institutions-line > .institutions-line-left {
  height: 100%;
  background: rgba(14, 18, 67, 0.5);
  box-sizing: border-box;
  border: 1px solid rgba(104, 101, 196, 0.5);
  width: 10.3rem;
  display: flex;
  padding-left: 1.8333rem;
  align-items: center;
}
.institutions-line > .institutions-line-left > .institutions-line-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.institutions-line:nth-child(2)
  > .institutions-line-left
  > .institutions-line-img {
  margin-right: 0.9rem;
}
.institutions-line:nth-child(3)
  > .institutions-line-left
  > .institutions-line-img {
  margin-right: 0.7rem;
}
.institutions-line > .institutions-line-left > .institutions-line-content {
  flex: 1;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 2rem;
  justify-content: space-evenly;
}
.institutions-line > .institutions-line-right {
  height: 100%;
  background: rgba(14, 18, 67, 0.5);
  box-sizing: border-box;
  border: 1px solid #6865c4;
  width: 10.333rem;
}
.institutions-line {
  height: 3.5rem;
}
.institutions-line:nth-child(1) {
  height: 4.3rem;
  margin-top: 1.2rem;
}

.institutions-count {
  font-size: 1.083333rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.institutions-desc {
  font-size: 0.58333rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8ec0ec;
}
</style>
