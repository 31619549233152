<template>
  <div class="community">
    <div class="community-line">
      <div class="community-line-item">
        <div class="community-line-img">
          <img
            src="../../../image/left/insCount.png"
            alt=""
            style="transform: scale(0.8)"
          />
        </div>
        <div class="community-line-content">
          <div class="community-count">{{ community.total + total }}</div>
          <div class="community-desc">综合体数</div>
        </div>
      </div>
      <div class="community-line-item">
        <div class="community-line-img">
          <img
            src="../../../image/left/communityBedCount.png"
            alt=""
            style="transform: scale(0.8)"
          />
        </div>
        <div class="community-line-content">
          <div class="community-count">{{ community.bedTotal + bedTotal }}</div>
          <div class="community-desc">床位总数</div>
        </div>
      </div>
      <div class="community-line-item">
        <div class="community-line-img">
          <img
            src="../../../image/left/memberCount.png"
            alt=""
            style="transform: scale(0.8)"
          />
        </div>
        <div class="community-line-content">
          <div class="community-count">{{ community.vipTotal + vipTotal }}</div>
          <div class="community-desc">会员总数</div>
        </div>
      </div>
    </div>
    <div class="community-line">
      <div class="community-line-items">
        <!-- <div class="community-line-img">
          <img src="../../../image/left/allCheck.png" alt="" />
        </div> -->
        <div class="community-line-content">
          <div class="community-count">
            {{ community.inBedTotal + inBedTotal }}
          </div>
          <div class="community-desc">总入住人数</div>
        </div>
      </div>
      <div class="community-line-items">
        <!-- <div class="community-line-img">
          <img src="../../../image/left/allService.png" alt="" />
        </div> -->
        <div class="community-line-content">
          <div class="community-count">
            {{ community.serviceTotal + serviceTotal }}
          </div>
          <div class="community-desc">总服务人次</div>
        </div>
      </div>
      <!-- <div class="community-line-item">
        <div class="community-line-content">
          <div class="community-count">
            {{ community.orderAmount | moneyDetails }}
          </div>
          <div class="community-desc">总消费金额</div>
        </div>
      </div> -->
    </div>
    <div class="community-line">
      <div>
        <img src="../../../image/left/monthAdd.png" alt="" />
      </div>
      <div class="new-add-item">
        <div class="newAddCount">{{ community.curInBedTotal }}</div>
        <div class="newAddDesc">入住人数</div>
      </div>
      <div class="new-add-item">
        <div class="newAddCount">{{ community.curVipTotal }}</div>
        <div class="newAddDesc">新增会员</div>
      </div>
      <div class="new-add-item">
        <div class="newAddCount">{{ community.curServiceTotal }}</div>
        <div class="newAddDesc">服务人次</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    community: {
      type: Object,
      default: {},
    },
    levelAndCode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    levelAndCode: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      const provinceName = sessionStorage.getItem('provinceName')
      const cityName = sessionStorage.getItem('cityName')
      if (this.levelAndCode) {
        if (this.levelAndCode.level == 'PROVINCE') {
          if (provinceName == '四川省') {
            this.total = 0
            this.bedTotal = 89
            this.vipTotal = 6746
            this.inBedTotal = 140
            this.serviceTotal = 281977
          } else if (provinceName == '云南省') {
            this.total = 2
            this.bedTotal = 121
            this.vipTotal = 3734
            this.inBedTotal = 112
            this.serviceTotal = 140663
          } else if (provinceName == '海南省') {
            this.total = 1
            this.bedTotal = 34
            this.vipTotal = 920
            this.inBedTotal = 29
            this.serviceTotal = 34104
          } else if (provinceName == '广西壮族自治区') {
            this.total = 1
            this.bedTotal = 67
            this.vipTotal = 0
            this.inBedTotal = 0
            this.serviceTotal = 0
          } else if (provinceName == '贵州省') {
            this.total = 1
            this.bedTotal = 34
            this.vipTotal = 468
            this.inBedTotal = 30
            this.serviceTotal = 17349
          } else {
            this.total = 0
            this.bedTotal = 0
            this.vipTotal = 0
            this.inBedTotal = 0
            this.serviceTotal = 0
          }
        } else if (this.levelAndCode.level == 'CITY') {
          if (cityName == '成都市') {
            this.total = 0
            this.bedTotal = 10
            this.vipTotal = 1199
            this.inBedTotal = 16
            this.serviceTotal = 69829
          } else if (cityName == '自贡市') {
            this.total = 0
            this.bedTotal = 23
            this.vipTotal = 825
            this.inBedTotal = 17
            this.serviceTotal = 30656
          } else if (cityName == '南充市') {
            this.total = 1
            this.bedTotal = 57
            this.vipTotal = 1893
            this.inBedTotal = 38
            this.serviceTotal = 70210
          } else if (cityName == '乐山市') {
            this.total = 1
            this.bedTotal = 48
            this.vipTotal = 1049
            this.inBedTotal = 31
            this.serviceTotal = 38886
          } else if (cityName == '雅安市') {
            this.total = 1
            this.bedTotal = 55
            this.vipTotal = 834
            this.inBedTotal = 33
            this.serviceTotal = 30916
          } else if (cityName == '眉山市') {
            this.total = 0
            this.bedTotal = 0
            this.vipTotal = 946
            this.inBedTotal = 5
            this.serviceTotal = 41480
          } else if (cityName == '红河哈尼族彝族自治州') {
            this.total = 0
            this.bedTotal = 36
            this.vipTotal = 1837
            this.inBedTotal = 44
            this.serviceTotal = 70341
          } else if (cityName == '曲靖市') {
            this.total = 1
            this.bedTotal = 52
            this.vipTotal = 888
            this.inBedTotal = 40
            this.serviceTotal = 32918
          } else if (cityName == '楚雄彝族自治州') {
            this.total = 1
            this.bedTotal = 33
            this.vipTotal = 1009
            this.inBedTotal = 28
            this.serviceTotal = 37404
          } else if (cityName == '海口市') {
            this.total = 1
            this.bedTotal = 34
            this.vipTotal = 920
            this.inBedTotal = 29
            this.serviceTotal = 34104
          } else if (cityName == '黔南布依族苗族自治州') {
            this.total = 1
            this.bedTotal = 34
            this.vipTotal = 468
            this.inBedTotal = 30
            this.serviceTotal = 17349
          } else if (cityName == '南宁市') {
            this.total = 1
            this.bedTotal = 67
            this.vipTotal = 0
            this.inBedTotal = 0
            this.serviceTotal = 0
          } else {
            this.total = 0
            this.bedTotal = 0
            this.vipTotal = 0
            this.inBedTotal = 0
            this.serviceTotal = 0
          }
        }
      } else {
        this.total = 5
        this.bedTotal = 461
        this.vipTotal = 11870
        this.inBedTotal = 585
        this.serviceTotal = 474094
      }
    },
  },
  data() {
    return {
      total: 0,
      bedTotal: 0,
      vipTotal: 0,
      inBedTotal: 0,
      serviceTotal: 0,
    }
  },
}
</script>

<style scoped>
.community {
  position: relative;
  margin-bottom: 0.3rem;
  height: 14rem;
  background: url(../../../image/left/CommunityService.png) 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: space-evenly;
}
.community > .community-line:nth-child(1) {
  height: 3.7rem;
  width: 95%;
  margin-top: 1.2rem;
  align-items: center;
  justify-content: space-between;
}
.community > .community-line:nth-child(2) {
  height: 3.3rem;
  width: 95%;
  align-items: center;
  justify-content: space-between;
}
.community > .community-line:nth-child(3) {
  height: 4.25rem;
  width: 95%;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  background: rgba(14, 18, 67, 0.5);
  border: 1px solid rgba(104, 101, 196, 0.5);
}
.community > .community-line {
  display: flex;
}
.community > .community-line > .community-line-item {
  width: 6.75rem;
  height: 100%;
  box-sizing: border-box;
  background: rgba(14, 18, 67, 0.5);
  border: 1px solid rgba(104, 101, 196, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.community > .community-line > .community-line-items {
  width: 10.32rem;
  height: 100%;
  box-sizing: border-box;
  background: rgba(14, 18, 67, 0.5);
  border: 1px solid rgba(104, 101, 196, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.community > .community-line:nth-child(1) > .community-line-item {
  justify-content: center;
}
.community > .community-line:nth-child(2) > .community-line-item {
  padding-left: 0.3rem;
}

.community > .community-line > .community-line-item > .community-line-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.community > .community-line > .community-line-item > .community-line-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 70%;
}
.community-count {
  font-size: 0.98rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}
.community-desc {
  font-size: 0.58333333333rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8ec0ec;
}

.new-add-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newAddCount {
  text-align: center;
  font-size: 1.25rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffab00;
}
.newAddDesc {
  font-size: 0.583333rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8ec0ec;
}
</style>
